import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Popup from "../../components/Popup";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
//Modals
import BrowseProducts from "../../components/OrderModals/BrowseProducts";
import AddCustomItem from "../../components/OrderModals/AddCustomItem";
import Tax from "../../components/OrderModals/Tax";
import AddDiscount from "../../components/OrderModals/AddDiscount";
import CustomProductsPO from "../../MobileComponents/OrderMobileComponents/CustomProductsPO";
import CustomShipping from "../../components/OrderModals/AddShipping";
import EditContactInfoModal from "../../components/OrderModals/EditContactInfoModal";
import ShippingAddressModal from "../../components/OrderModals/ShippingAddressModal";
import helpers from "../../UtilitiesFunctions";
import { withStyles } from "@material-ui/core/styles";
import CancelIcon from "@mui/icons-material/Cancel";
import { Button, Chip } from "@material-ui/core";
import { connect } from "react-redux";
import Store from "../../index";
import AddCustomerModal from "../../components/Customers/AddCustomerModal";

import Typography from "@mui/material/Typography";

import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import * as localForage from "localforage";
import Loader from "../../MobileComponents/Loader";
import CustomSnackbar from "../../MobileComponents/ProductMobileComponents/Snackbar";
import { SKY_BLUE, MARS, WHITE } from "../../colors/colors";
import {
  getDraftOrders,
  getCloseOrders,
  getOpenOrders,
  getUnpaidOrders,
  getUnfulfilledOrders,
} from "../../utilities/OrdersFilter";
import { ThermalPrinterView } from "../../components/print/thermalPrinterView";
// import { Autocomplete } from "@material-ui/lab";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
const style = (theme) => ({
  [theme.breakpoints.down("sm")]: {
    "@global": {
      "*::-webkit-scrollbar": {
        width: "6px",
        height: "6px",
      },
      "*::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 0px rgba(0,0,0,0.00)",
      },
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: "rgb(66, 63, 62)",
        border: "1px solid black",
        borderRadius: "13px",
        outline: "1px solid slategrey",
      },
    },
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),

    textAlign: "",
    color: theme.palette.text.primary,
  },
  paperSmall: {
    height: "150px",
    overflowY: "scroll",
  },
  papertm: {
    padding: theme.spacing(1),
    textAlign: "",
    color: theme.palette.text.primary,
    marginTop: theme.spacing(2),
  },
  header: {
    padding: theme.spacing(1),
    textAlign: "",
    color: theme.palette.text.primary,
    //bgcolor: theme.palette.backgroundColor.primary,
    backgroundColor: "lightgray",
  },
  box: {
    display: "inline-flex",
  },
  boxRow: {
    display: "inline-flex",
    flexDirection: "row",
  },
});

class PurchaseOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addCustomItemModal: false,
      addCustomerModal: false,
      addDiscountModal: false,
      addShippingModal: false,
      addTaxModal: false,
      browseProductsModal: false,
      editInfoModal: false,
      ShippingAddressModal: false,
      snackbar: false,
      products: [],
      lineItems: [],
      customers: [], //info needed here
      currentCustomer: "",
      paid: false, //paid status on btn mark as paid
      billStatus: "pending", //bill status on btn mark as pending
      loader: false,
      loaderMessage: "Loading Orders",
      open: true,
      costPrice: 0,
      errorResponse: "",
      customSnackbar: false,
      snackbarMessage: "",
      snackbarType: "success",
      taxTotal: 0,
      totalAmount: 0,
      email: "",
      phoneNumber: "",
      name: "",
      orderObject: undefined,
      printReceipt: false,
      discount: undefined,
      selectedOption: "individual",
      individuals: "",
      organization: "",
      purchaseorderid: "",
    };
  }
  debounce = (func, delay) => {
    console.log("inDebounced Function");
    let inDebounce;
    return function () {
      clearTimeout(inDebounce);
      inDebounce = setTimeout(() => func(), delay);
    };
  };
  handleError = async (response) => {
    // console.log("response is: ", response);
    if (response.status === 403) {
      const errorResponse = await response.json();
      this.setState({ errorResponse: errorResponse });
      console.log("response is: ", errorResponse);
      const { message, errors } = errorResponse;
      this.setState({
        customSnackbar: false,
        snackbarMessage: message,
        snackbarType: "error",
      });

      helpers.toastNotify("error", message);
    }
  };
  renderSnackbar = (message, type) => {
    this.setState({
      customSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  addProduct = (resp) => {
    // console.log(resp);
    this.setState(function (prevState) {
      return {
        products: [
          ...prevState.products,
          {
            productId: resp.POID,
            productName: resp.name, //productName
            price: resp.price,
            tax: resp.taxRate,
            unitOfMeasure: resp.unitsOfMeasures[0].POID,
            action: "add",
            fulfilled: false,
            quantityAvailable: resp.quantity, //available qty
            costPrice: resp.costPrice,
          },
        ],
      };
    });
  };

  componentDidMount = () => {
    let customer;
    if (this.props.g_orders.length === 0) {
      this.enableLoader("Loading Orders..");

      let url = this.props.global_config.basePath + "/orders";
      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer token",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          Store.dispatch({ type: "GET_ORDERS", orders: data });
          Store.dispatch({
            type: "UNFULFILLED_ORDERS",
            unfulfilled_orders: getUnfulfilledOrders(data),
          });
          Store.dispatch({
            type: "UNPAID_ORDERS",
            unpaid_orders: getUnpaidOrders(data),
          });
          Store.dispatch({
            type: "DRAFT_ORDERS",
            draft_orders: getDraftOrders(data),
          });
          Store.dispatch({
            type: "CLOSE_ORDERS",
            close_orders: getCloseOrders(data),
          });
          Store.dispatch({
            type: "OPEN_ORDERS",
            open_orders: getOpenOrders(data),
          });
          this.disableLoader();
        })
        .catch((err) => {
          this.disableLoader();

          console.log(err);
        });
    }

    if (this.props.g_active_customers.length === 0) {
      let url = this.props.global_config.basePath + "/customers?status=Active";

      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer token",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            customers: data,
            previousCustomers: data,
            loading: false,
          });

          Store.dispatch({
            type: "GET_ACTIVE_CUSTOMERS",
            active_customers: data,
          });
        })
        .catch((err) => {});
    } else {
      this.setState({ customers: this.props.g_active_customers });
    }

    try {
      customer = window.history.state.state;
    } catch {}
    if (customer !== undefined) {
      this.handleOnSelect(customer, "customer");
    }
  };
  handleOrderTypesInStore = (orders) => {
    let finalOrder;

    if (!orders) return;

    if (Array.isArray(orders)) {
      finalOrder = orders[0];
    } else {
      finalOrder = orders;
    }
    const { fulfilled, paid, partiallyFulfilled } = finalOrder;
    if (fulfilled === false && paid === false) {
      Store.dispatch({
        type: "POST_MARK_AS_PENDING_ORDERS",
        unfulfilled_orders: finalOrder,
      });
    } else if (paid) {
      Store.dispatch({
        type: "POST_MARK_AS_PAID_ORDERS",
        orders: finalOrder,
      });
    }
  };

  updateProductQuantity = (productIds, productQuantity) => {
    Store.dispatch({
      type: "EDIT_INVENTORY_ITEM_QUANTITY_ON_ORDER",
      id: productIds,
      quantity: productQuantity,
    });
    Store.dispatch({
      type: "EDIT_ACTIVE_PRODUCTS_QUANTITY_ON_ORDER",
      id: productIds,
      quantity: productQuantity,
    });
  };

  getInventory = (incomingProduct) => {
    const products = incomingProduct.map((item) => {
      const inventoryItem = this.props.g_inventoryItems.find(
        (inv) => inv.POID === item.id
      );

      if (inventoryItem) {
        return {
          ...item,
          stock: inventoryItem.quantity,
        };
      }

      return item;
    });

    console.log("products line items selected:", products);
    return products;
  };

  fetchSpecificOrder = async (resp) => {
    let productIds = [];
    let productQuantity = [];
    const orderResponse = await resp.json();
    console.log("in fetchSpecificOrder: response is -> ", orderResponse);
    let url =
      this.props.global_config.basePath + "/orders/" + orderResponse.orderId;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
    })
      .then(async (response2) => {
        const fetchedOrder = await response2.json();
        console.log("fetched response is: ", await fetchedOrder);
        this.handleOrderTypesInStore(fetchedOrder);
        for (let i in fetchedOrder[0].lineItems) {
          productIds.push(fetchedOrder[0].lineItems[parseInt(i)].productId);
          productQuantity.push(fetchedOrder[0].lineItems[parseInt(i)].quantity);
        }

        this.updateProductQuantity(productIds, productQuantity);
        if (this.state.currentCustomer !== "") {
          Store.dispatch({
            type: "EDIT_CUSTOMER_ON_ORDER",
            orders: fetchedOrder[0],
          });
        }
      })
      .then(() => {
        this.props.history.push({
          pathname: "/ViewOrders",
        });
      })
      .catch((err) => {
        console.log(err);
      });

    this.setState(function (currentState) {
      return { loader: false };
    });
  };
  handleClickOpen = (name) => {
    if (name === "addCustomItemModal") {
      this.setState({ addCustomItemModal: true });
    } else if (name === "addDiscountModal") {
      this.setState({ addDiscountModal: true });
    } else if (name === "browseProductsModal") {
      this.setState({ browseProductsModal: true });
    } else if (name === "addTaxModal") {
      this.setState({ addTaxModal: true });
    } else if (name === "addShippingModal") {
      this.setState({ addShippingModal: true });
    } else if (name === "editInfoModal") {
      this.setState({ editInfoModal: true });
    } else if (name === "ShippingAddressModal") {
      this.setState({ ShippingAddressModal: true });
    } else if (name === "AddCustomerModal") {
      this.setState({ addCustomerModal: true });
    }
  };

  enableLoader = (message) => {
    this.setState({ loader: true, loaderMessage: message });
  };
  disableLoader = () => {
    this.setState({ loader: false });
  };
  totalLineItems = () => {
    let total = 0.0;

    this.state.lineItems.map(
      (lineItem) => (total = total + lineItem.price * lineItem.quantity)
    );

    if (total <= 0) return "Rs 0.00";
    else return total;
  };
  calculateTax = () => {
    let totalTax = 0.0;
    const { lineItems } = this.state;
    lineItems.map(
      (lineItem) =>
        (totalTax = totalTax + lineItem.price * (lineItem.tax / 100))
    );
    if (totalTax <= 0) return 0.0;
    return totalTax;
  };
  calculateTotal = () => {
    var totalAmount = 0.0;
    var totalTax = 0.0;
    const { lineItems } = this.state;
    console.log(lineItems);
    lineItems.forEach((lineItem) => {
      console.log("in tax calculateTotal function");
      totalTax = totalTax + lineItem.price * (lineItem.tax / 100);
      console.log("total tax is: ", totalTax);
    });
    lineItems.map(
      (lineItem) =>
        (totalAmount += totalTax + lineItem.price * lineItem.quantity)
    );
    if (totalAmount <= 0) return 0;

    const values = { totalAmount, totalTax };
    console.log("values are: " + JSON.stringify(values));
    return values;
  };

  closeLoader = () => {
    this.setState({ loader: false });
  };

  handleClose = (name) => {
    if (name === "addCustomItemModal") {
      this.setState({ addCustomItemModal: false });
    } else if (name === "addDiscountModal") {
      this.setState({ addDiscountModal: false });
    } else if (name === "browseProductsModal") {
      this.setState({ browseProductsModal: false });
    } else if (name === "addTaxModal") {
      this.setState({ addTaxModal: false });
    } else if (name === "addShippingModal") {
      this.setState({ addShippingModal: false });
    } else if (name === "editInfoModal") {
      this.setState({ editInfoModal: false });
    } else if (name === "ShippingAddressModal") {
      this.setState({ ShippingAddressModal: false });
    } else if (name === "AddCustomerModal") {
      this.setState({ addCustomerModal: false });
    }
  };

  addItem = (item, varName) => {
    if (varName === "product") {
      this.setState(function (currentState) {
        return {
          lineItems: [
            ...currentState.lineItems,
            {
              productId: item.productId,
              productName: item.productName, //productName
              price: item.price,
              tax: item.tax,
              unitOfMeasure: item.unitOfMeasure,
              action: "add",
              fulfilled: false,
              quantity: 1,
              costPrice: item.costPrice,
            },
          ],
        }; //adding items in
      });
    } else if (varName === "customer") {
      this.setState({ currentCustomer: item });
    } else if (varName === "organization") {
      this.setState({ organization: item });
    } else if (varName === "individual") {
      this.setState({ individuals: item });
    }
  };

  updateMyCounter = (data, dest, products) => {
    if (dest === "customItem") {
      if (this.state.lineItems.length === 0) {
        this.setState({ lineItems: [data] });
        this.handleClose("addCustomItemModal");
      } else {
        this.setState({ lineItems: [...this.state.lineItems, data] });
        this.handleClose("addCustomItemModal");
      }
    } else if (dest === "dbItem") {
      if (this.state.lineItems.length !== 0) {
        this.state.lineItems.map((existingItem) => {
          data = data.filter(
            (newItem) => newItem.productId != existingItem.productId
          );
        });
      }
      this.setState({
        lineItems: [...this.state.lineItems, ...data],
      });

      this.handleClose("browseProductsModal");
    }
  };

  updateQty = (qty, name) => {
    const array = this.state.lineItems;
    for (var i = 0; i < this.state.lineItems.length; i++) {
      if (array[i].productName === name) {
        array[i] = { ...array[i], quantity: qty };
      }
    }
    this.setState(
      {
        lineItems: array,
      },
      () => {
        this.addDiscount(this.state.discount);
      }
    );
  };

  updatePrice = (price, name) => {
    const array = this.state.lineItems;
    for (var i = 0; i < this.state.lineItems.length; i++) {
      if (array[i].productName === name) {
        array[i] = { ...array[i], price: price };
      }
    }
    this.setState(
      {
        lineItems: array,
      },
      () => {
        this.addDiscount(this.state.discount);
      }
    );
  };

  addDiscount = (props) => {
    console.log("here is discount props", props);
    this.setState(
      {
        discount: props,
      },
      () => {
        if (
          helpers.getDiscountAmount(this?.state?.discount) >
          helpers.taxAddedTotal()
        ) {
          this.setState({ discount: undefined });
          helpers.toastNotify(
            "error",
            "Discount amount cannot be greater than the total amount"
          );
          return;
        } else {
          this.handleClose("addDiscountModal");
        }
      }
    );
  };

  onDelete = (e) => {
    var name = null;
    if (e.productId === null) {
      name = e.productName;
      this.setState(
        {
          lineItems: this.state.lineItems.filter(
            (item) => item.productName !== name
          ),
        },
        () => {
          console.log("this?.state?.lineItems", this.state.lineItems);
          this.addDiscount(this.state.discount);
        }
      );
    } else {
      name = e.productId;
      this.setState(
        {
          lineItems: this.state.lineItems.filter(
            (item) => item.productId !== name
          ),
        },
        () => {
          console.log("this?.state?.lineItems", this.state.lineItems);
          this.addDiscount(this.state.discount);
        }
      );
    }
  };

  closeLoading = () => {
    this.setState({ loader: false });
  };

  onPrintFinish = () => {
    this.setState({ printReceipt: false });
    this.setState({
      lineItems: [],
      currentCustomer: "",
    });
  };

  handleOptionChange = (event) => {
    // setSelectedOption(event.target.value);
    this.setState({ selectedOption: event.target.value }, () => {
      console.log("here is all selected options", this.state.selectedOption);
    });
  };

  postOrders(ss) {
    let orderObject = {};
    let orderKey = "";
    localForage
      .iterate(function (value, key, iterationNumber) {
        console.log(key);
        console.log([key, value]);
        Object.assign(orderObject, value);
        console.log(orderObject);
        orderKey = key;
        console.log("orderKey", orderKey);
        console.log("orderObject", orderObject);
        return value, key, iterationNumber;
        // return orderObject
      })
      .then(function (orderObject) {
        console.log(orderObject);

        console.log("Iteration has completed");
      })
      .catch(function (err) {
        console.log(err);
      });
    orderObject?.lineItems?.forEach((o) => {
      console.log(o);
      o.id = undefined;
      delete o.id;
    });
    console.log("Order object before request order create API: ");
    console.log(orderObject);
    let url = this.props.global_config.basePath + "/orders";
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
      // convert the React state to JSON and send it as the POST body
      body: JSON.stringify(orderObject),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.status === 201) {
          this.setState({ orderObject: response, printReceipt: true });
          localForage.removeItem(orderKey, (err, value) => {
            console.log(err, value);
          });
        }
      })
      .then(() => {
        localForage.clear();
      })
      .catch(function (err) {
        localForage.clear();
        helpers.toastNotify("error", "Could Not Create Order.");
      });
  }

  offlineOrder = (status) => {
    let productIds = [];
    let productQuantity = [];
    let offlineCreateOrder = null;
    let subTotal = this.state.lineItems.reduce((total, item) => {
      return total + item.price * item.quantity;
    }, 0);
    let taxAmount = this.state.lineItems.reduce((total, item) => {
      return total + (item.price * item.quantity * item.tax) / 100;
    }, 0);
    let orderID = (
      Math.max.apply(
        Math,
        this.props.g_orders.map((o) => {
          return o.orderId;
        })
      ) + 1
    ).toString();

    if (!Number.isInteger(orderID)) {
      orderID = Math.floor(Math.random() * 100000);
    } else {
      orderID = orderID.replace(/,/g, "");
    }

    offlineCreateOrder = {
      customerId: this.state.currentCustomer.id
        ? this.state.currentCustomer.id
        : null,
      lineItems: this.state.lineItems,
      paid: status,
      active: true,
      close: false,
      createdAt: this.getOfflineDate(),
      draft: false,
      fulfilled: false,
      fulfillmentDate: "null",
      orderId: orderID.replace(/,/g, ""),
      partiallyFulfilled: false,
      paymentDate: this.getOfflinePaymentDate(),
      paymentId: "",
      returned: false,
      returnedAmount: 0,
      status: "Active",
      subTotal: subTotal,
      taxAmount: taxAmount,
      taxRate: this.state.lineItems[0].tax,
      total: subTotal + taxAmount,
    };
    delete offlineCreateOrder.billUnitId;
    delete offlineCreateOrder.billStatus;
    delete offlineCreateOrder.currencyCode;

    this.handleOrderTypesInStore(offlineCreateOrder);
    for (let i in offlineCreateOrder.lineItems) {
      productIds.push(offlineCreateOrder.lineItems[parseInt(i)].productId);
      productQuantity.push(offlineCreateOrder.lineItems[parseInt(i)].quantity);
    }
    this.updateProductQuantity(productIds, productQuantity);
    if (this.state.currentCustomer !== "") {
      Store.dispatch({
        type: "EDIT_CUSTOMER_ON_ORDER",
        orders: offlineCreateOrder,
      });
    }
  };

  markAsPurchase = () => {
    this.enableLoader("Creating Orders in process..");
    let purchaseOrderItems = [];

    // Loop through each product and create purchaseOrderItems format
    this.state.lineItems.forEach((product) => {
      let purchaseOrderItem = {
        quantity: product.quantity,
        pricePerUnit: product.price,
        totalPrice: product.quantity * product.price,
        Product_Id: product.productId,
      };
      purchaseOrderItems.push(purchaseOrderItem);
    });

    console.log("here is purchase order item,", purchaseOrderItems);

    let buypurhase = {
      status: "pending",
      remarks: "Urgent delivery needed",
      Vendor_Id: this.state.individuals.POID || this.state.organization.POID,
      purchaseOrderItems: purchaseOrderItems,
    };

    let urll =
      this.props.global_config.basePath +
      "/PurchaseOrderManagement/purchaseOrder";
    fetch(urll, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer saad",
      },
      body: JSON.stringify(buypurhase),
    })
      .then(async (response) => {
        const data = await response.json();
        console.log("here is response from purchase order", data.id);
        this.setState({ purchaseorderid: data.id });
        this.disableLoader();
      })
      .catch((error) => {
        helpers.toastNotify("error", "something went wrong");
        this.disableLoader();
      });
  };

  markAsPaid = () => {
    console.log("here is line item on checkout", this.state.lineItems);
    const invalidEntries = this.state.lineItems.filter(
      (lineItem) =>
        lineItem.price === null ||
        lineItem.price === "" ||
        lineItem.price === 0 ||
        isNaN(lineItem.price) ||
        lineItem.quantity === null ||
        lineItem.quantity === "" ||
        lineItem.quantity === 0 ||
        isNaN(lineItem.quantity)
    );

    if (invalidEntries.length > 0) {
      helpers.toastNotify("error", "Inputs fields can not be empty or Zero");
      return;
    }

    let createOrder = {
      paid: true,
      billUnitId: 2,
      currencyCode: "PKR",
      billStatus: "pending",
      customerId: this.state?.currentCustomer?.id,
      lineItems: this.state.lineItems,
      discountAmount: helpers.getDiscountAmount(this?.state?.discount),
      discountReason:
        this.state.discount?.discount_reason === ""
          ? null
          : this.state.discount?.discount_reason,
    };

    this.enableLoader("Please Wait...");

    let urll = this.props.global_config.basePath + "/orders";
    fetch(urll, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer saad",
      },
      body: JSON.stringify(createOrder),
    })
      .then(async (response) => ({
        response: await response.json(),
        status: response.status,
      }))
      .then(({ response, status }) => {
        this.disableLoader();
        console.log("here is response from exceed quantity", response);
        if (status !== 200) {
          helpers.toastNotify("error", response.message);
          return;
        }
        let productIds = [];
        let productQuantity = [];

        this.state.lineItems.map((item) => {
          if (item.productId) {
            productIds.push(item.productId);
            productQuantity.push(item.quantity);
          }
        });
        Store.dispatch({
          type: "APPEND_ORDERS",
          orders: response,
        });
        this.updateProductQuantity(productIds, productQuantity);

        this.setState({ orderObject: response, printReceipt: true });

        console.log(response);

        helpers.toastNotify("success", "Order Successfully Created!");
        this.setState({ discount: "" });
      })
      .catch((e) => {
        this.disableLoader();
        this.setState({
          lineItems: [],
          currentCustomer: "",
        });
        helpers.toastNotify("error", "Something Went Wrong.");
      });
  };

  markAsPending = () => {
    this.enableLoader("Please Wait...");

    let createOrder = {
      paid: false,
      billUnitId: 2,
      currencyCode: "PKR",
      billStatus: "pending",
      customerId: this.state?.currentCustomer?.id,
      lineItems: this.state.lineItems,
    };

    let urll = this.props.global_config.basePath + "/orders";

    fetch(urll, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer saad",
      },
      body: JSON.stringify(createOrder),
    })
      .then((response) => response.json())
      .then((response) => {
        this.disableLoader();
        this.setState({
          lineItems: [],
          currentCustomer: "",
        });

        this.setState({ orderObject: response, printReceipt: true });
        helpers.toastNotify("success", "Order Successfully Created!");
      })
      .catch((e) => {
        this.disableLoader();
        this.setState({
          lineItems: [],
          currentCustomer: "",
        });
        helpers.toastNotify("error", "Something Went Wrong.");
      });
  };

  addZero = (x, n) => {
    while (x.toString().length < n) {
      x = "0" + x;
    }
    return x;
  };
  getOfflineDate = () => {
    const d = new Date();
    let day = this.addZero(d.getDate(), 2);
    let month = this.addZero(d.getMonth() + 1, 2);
    let year = d.getFullYear();
    let h = this.addZero(d.getHours(), 2);
    let m = this.addZero(d.getMinutes(), 2);
    let s = this.addZero(d.getSeconds(), 2);
    let ms = this.addZero(d.getMilliseconds(), 3);
    let time =
      year + "." + month + "." + day + "." + h + "." + m + "." + s + "." + ms;
    return time;
  };
  getOfflinePaymentDate = () => {
    const d = new Date();
    let day = this.addZero(d.getDate(), 2);
    let month = this.addZero(d.getMonth() + 1, 2);
    let year = d.getFullYear();
    return year + "-" + month + "-" + day;
  };

  createOrderWithInvoice = () => {
    console.log(this.state);

    this.enableLoader("Please Wait...");
    if (this.state.currentCustomer.length < 1) {
      helpers.toastNotify("error", "Customer Not Selected!");
      this.disableLoader();
      this.setState({
        lineItems: [],
      });
    } else {
      let total = 0;
      let subtotal = 0;
      let emailTemplate = `
          <body style="font-family: sans-serif;background-color:white;background: url('https://i.postimg.cc/d1Qmcgmm/f50524ee5f161f437400aaf215c9e12f.jpg') cover; ">
            <table style="width: 100%; text-align: center;padding-bottom: 10px;background-color:white;background: url('https://i.postimg.cc/d1Qmcgmm/f50524ee5f161f437400aaf215c9e12f.jpg') cover">
              <tr>
                <td>
                  <img src="https://i.postimg.cc/xdZHBq8Y/logo.png" width="10%" />
                </td>
              </tr>
              <tr>
                <td style="font-weight: bold;padding-bottom: 10px;">HULM</td>
              </tr>
              <tr>
                <td style="padding-bottom: 10px;">ORDER # 3997</td>
              </tr>
              <tr>
                <td style="font-weight: bold;padding-bottom: 10px;">THANK YOU</td>
              </tr>
            </table>
        
            <table style="width: 100%;padding:15px 0px;background-color:  #50a5ac;color: white;">
              <tr>
                <th style="width: 20%">IMAGE</th>
                <th style="width: 60%">PRODUCT NAME</th>
                <th style="width: 20%">PRODUCT QUANTITY</th>
              </tr>
            </table>
        
            <table style="width: 100%; text-align: center">`;

      this.state.lineItems.map((item) => {
        subtotal += item.price * item.quantity;
        total = subtotal;
        emailTemplate +=
          `<tr>
                <td style="width: 20%">
                  <img src="https://i.postimg.cc/xdZHBq8Y/logo.png" style="width: 100px" />
                </td>
                <td style="width: 60%">` +
          item.productName +
          `</td>
                <td style="width: 20%">` +
          item.quantity +
          `</td>
              </tr>`;
      });

      emailTemplate +=
        `</table>
        
            <table
              style="
                width: 100%;
                background-color: #50a5ac;
                color: white;
                padding: 20px 30px;
              "
            >
              <td style="width: 75%">
                <tr>
                  <td></td>
                  <td>Subtotal:</td>
                  <td style="font-weight: bold">` +
        subtotal +
        `</td>
                </tr>
              </td>
              <td>
                <tr>
                  <td></td>
                  <td>Shipping:</td>
                  <td>Calculated at next step</td>
                </tr>
              </td>
              <td>
                <tr>
                  <td></td>
                  <td style="font-weight: bold">Taxes:</td>
                  <td>62.1231231212</td>
                </tr>
              </td>
            </table>
            <table style="width: 100%; background-color: #2e6d72; color: white">
              <td>
                <tr style="width: 26%; float: right">
                  <td style="width: 50%; font-weight: bold">TOTAL:</td>
                  <td style="width: 23%; font-weight: bold">` +
        total +
        `</td>
                </tr>
              </td>
            </table>
          </body>
        `;

      let url = "https://email.hulmsolutions.com/api/email/sendhtml";

      let emailObj = {
        url: "string",
        name: "string",
        receiverEmail: this.state.currentCustomer.email,
        email: this.state.currentCustomer.email,
        companyName: "string",
        message: emailTemplate,
        emailhtmlBody: emailTemplate,
        emailSubject: "Order Invoice - HULM POS",
        path: "path",
        adminDetails: { adminUserName: "string", adminPassword: "string" },
      };

      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer token",
        },
        body: JSON.stringify(emailObj),
      })
        .then((response) => response.json())
        .then((response) => {
          this.disableLoader();
          helpers.toastNotify(
            "success",
            `Invoice Sent To ${this.state.currentCustomer.name}`
          );
          this.setState({
            lineItems: [],
            currentCustomer: "",
          });
        })
        .catch((error) => {
          this.disableLoader();
          this.setState({
            lineItems: [],
            currentCustomer: "",
          });
          helpers.toastNotify("error", `Something went wrong!`);
        });
    }
  };

  createOrderAndSendInvoice = () => {
    var createOrder = null;
    if (!this.state.currentCustomer.id) {
      alert("Please Select a customer to send the invoice");
      return;
    }
    if (
      this.state.lineItems.length > 0 &&
      helpers.checkQuantity(this.state.lineItems, this.state.products)
    ) {
      this.setState(function (currentState) {
        return { loader: true };
      });

      createOrder = {
        customerId: this.state.currentCustomer.id
          ? this.state.currentCustomer.id
          : null,
        lineItems: this.state.lineItems,
        paid: false,
      };

      let stringifyString;
      if (createOrder != null) {
        stringifyString = JSON.stringify(createOrder);
      }
      console.log(stringifyString);
      let url = this.props.global_config.basePath + "/orders/sendInvoice";

      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer token",
        },
        body: stringifyString, //JSON.stringify(createOrder),
      })
        .then((response) => {
          if (response.status === 403) this.handleError(response);
          else if (response.status === 200) this.fetchSpecificOrder(response);
        })
        .catch(() => {
          helpers.toastNotify("error", "Something Went Wrong.");

          this.setState({ loader: false });
        });
    } else {
      this.setState({ snackbar: false });
      helpers.toastNotify("error", "Error Occured!");
    }
  };
  getCustomerData = (customerID) => {
    console.log("Cusotmer ID: ", customerID);
    if (customerID !== null && customerID !== "null") {
      const customers = this.props.g_active_customers.concat(
        this.props.g_archived_customers
      );

      const customerData = customers.find(
        (customer) => customer.id === customerID
      );
      this.setState({ customerData: customerData });
    }
  };
  updateShippingAddress = (shippingAddress) => {
    const updateAddres = {
      address: [
        {
          addressContactId: this.state.currentCustomer.address[0].addressId,
          addressId: this.state.currentCustomer.address[0].addressId,
          city: shippingAddress.city,
          country: shippingAddress.country,
          postCode: shippingAddress.postal_code,
          stateOrProvince: shippingAddress.stateOrProvince,
          street: shippingAddress.address,
          preferred: true,
        },
      ],
    };

    let url =
      this.props.global_config.basePath +
      `/customers/${this.state.currentCustomer.POID}`;

    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },

      body: JSON.stringify(updateAddres),
    })
      .then((resp) => {
        console.log("Resp" + resp);
        let url =
          this.props.global_config.basePath +
          `/customers/${this.state.currentCustomer.POID}`;

        fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer my-token",
          },
        })
          .then((response2) => response2.json())
          .then((data2) => {
            Store.dispatch({
              type: "EDIT_ACTIVE_CUSTOMER",
              updated_customer: data2,
            });
          });
        this.getCustomerData(this.state.currentCustomer.POID);
      })
      .catch((err) => {
        this.setState({ backdrop: false, errorSnackbar: false });
        helpers.toastNotify("error", "Could Not Update Shipping Address.");
      });
    this.handleClose("ShippingAddressModal");
  };
  updateContactInfo = (data) => {
    console.log("data from contact modal...", data);
    this.handleClose("editInfoModal");
    const updateData = {
      email: data.email,
      phoneNumber: data.phone_number,
      phoneNumberContactId: this.state.currentCustomer.phoneNumberContactId,
      phoneNumberId: this.state.currentCustomer.phoneNumberId,
      emailContactId: this.state.currentCustomer.emailContactId,
      emailId: this.state.currentCustomer.emailId,
      name: data.name,
      querry: "name",
    };

    console.log(updateData);

    let url =
      this.props.global_config.basePath +
      `/customers/${this.state.currentCustomer.POID}`;

    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },

      body: JSON.stringify(updateData),
    })
      .then((resp) => {
        let url =
          this.props.global_config.basePath +
          `/customers/${this.state.currentCustomer.POID}`;

        fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer my-token",
          },
        })
          .then((response2) => response2.json())
          .then((data2) => {
            Store.dispatch({
              type: "EDIT_CUSTOMERS",
              updated_customer: data2,
            });
            this.setState({ currentCustomer: data2 });
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handleOnSearchCustomers = (string, results) => {
    console.log(string, results);
  };

  handleOnSearchProducts = (string, results) => {
    console.log(string, results);
  };

  handleOnSearchCustomers = (string, results) => {
    console.log(string, results);

    console.log("here is vendors", this.props.g_vendors);
  };

  handleOnSelect = (item, name) => {
    if (name === "product") {
      this.addItem(item, name);
    } else if (name === "customer") {
      this.addItem(item, name);
    } else if (name === "organization") {
      this.addItem(item, name);
    } else if (name === "individual") {
      this.addItem(item, name);
    }
  };

  closeSnack = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackbar: false });
  };
  onHandleBack = (props) => {
    this.props.history.push("/");
  };
  setSnackbarToFalse = () => {
    setTimeout(() => {
      this.setState({ customSnackbar: false });
    }, 3000);
  };

  handleDiscountsChange = (e, selectedvalue) => {
    console.log(
      "here is discount change when discount change",
      e.target,
      selectedvalue
    );
  };

  render() {
    const { classes } = this.props;
    const discounts = this.props.g_discount_state;
    console.log("here is all the discounts", discounts.discounts);

    console.log("here is organizations", this.state.organization);

    console.log("here is lineitems", this.state.lineItems);
    const {
      customSnackbar,
      snackbarMessage,
      snackbarType,
      loader,
      loaderMessage,
    } = this.state;
    console.log("here is order created check", this.state.lineItems);
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    if (customSnackbar) {
      //agar snackbar true hai to usay false karde wrna wo har render bar display hoga
      console.log("in snackbar condiiton");
      this.setSnackbarToFalse();
    }

    return (
      <>
        {this.state.loader ? (
          <>
            <Loader value={loaderMessage} />
          </>
        ) : (
          <>
            {this.state.purchaseorderid ? (
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: "0.8rem",
                  padding: "25px",
                  marginBottom: "20px",
                  boxShadow:
                    "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                  outline: ".1rem solid transparent",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "600",
                      fontSize: "1rem",
                    }}
                  >
                    Created Order
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img src={"../assets/addcustomer.svg"} alt="addCutomer" />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "0% 0% 3% 0%",
                  }}
                >
                  <h4>
                    Your Purchase Order Created Successfully here is your
                    Purchase Order No: {this.state.purchaseorderid}{" "}
                  </h4>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    onClick={() => {
                      this.props.history.push("/Purchase Orders");
                      this.setState({
                        purchaseorderid: "",
                        lineItems: "",
                        individuals: "",
                        organization: "",
                      });
                    }}
                    style={{ backgroundColor: MARS, color: WHITE }}
                    varian="contained"
                    size="large"
                  >
                    Go Back
                  </Button>
                </div>
              </div>
            ) : (
              <div className={classes.root}>
                {this.state.snackbar ? <></> : null}
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Paper className={classes.rounded}>
                      <Box
                        className={classes.box}
                        p={1}
                        width="100%"
                        style={{
                          backgroundColor: SKY_BLUE,
                        }}
                      >
                        <Grid
                          container
                          style={{
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Grid item style={{ marginLeft: "1%" }}>
                            <Typography
                              variant="h5"
                              align="center"
                              sx={{ color: "white" }}
                            >
                              Purchase Orders
                            </Typography>
                          </Grid>
                          <Grid item style={{ marginRight: "1%" }}></Grid>
                        </Grid>
                      </Box>
                    </Paper>
                  </Grid>

                  <Grid item lg={8} sm={12} xs={12} md={12}>
                    <Paper className={classes.paper}>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            backgroundColor: "white",
                          }}
                        >
                          <Box display="inline-flex" p={1} width="100%">
                            <Box p={1} flexGrow={1}>
                              <h5>Create Orders</h5>
                            </Box>
                          </Box>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            backgroundColor: "white",
                          }}
                        >
                          <Box display="inline-flex" p={1} width="100%">
                            <Box p={1}>
                              <BrowseProducts
                                updateParent={this.updateMyCounter}
                                onClickBtnClose={() =>
                                  this.handleClose("browseProductsModal")
                                }
                              />
                            </Box>
                          </Box>
                        </div>
                        <div style={{ display: "block", width: "100%" }}>
                          <Box>
                            {this.state.lineItems.length > 0 ? (
                              <CustomProductsPO
                                products={this.getInventory(
                                  this.state.lineItems
                                )}
                                onDelete={this.onDelete}
                                isDelete={true}
                                getQty={this.updateQty}
                                updatePrice={this.updatePrice}
                                isPriceUpdateable={true}
                                isRefund={false}
                              />
                            ) : (
                              ""
                            )}
                          </Box>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            backgroundColor: "white",
                          }}
                        ></div>

                        {this.state.lineItems.length > 0 ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                                backgroundColor: "white",
                              }}
                            >
                              <Box display="flex" flexDirection={"row"} p={1}>
                                <Box p={1}></Box>
                              </Box>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                backgroundColor: "white",
                                justifyItems: "flex-end",
                              }}
                            >
                              <Box display="flex" p={1} width="100%">
                                {/* <Box p={1} flexGrow={1}> */}
                                <>{/* <DescriptionIcon /> ACCEPT PAYMENT */}</>
                                {/* </Box> */}
                                <Box>
                                  <Button
                                    style={{
                                      textTransform: "none",
                                      marginLeft: "10px",
                                    }}
                                    //color="dark"
                                    variant="contained"
                                    //component={Link}
                                    //to="/ViewOrder"
                                    onClick={this.markAsPurchase}
                                  >
                                    Purchase
                                  </Button>
                                </Box>
                              </Box>
                            </div>
                            {customSnackbar ? (
                              <CustomSnackbar
                                message={snackbarMessage}
                                type={snackbarType}
                              />
                            ) : null}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    {this.state.individuals === "" &&
                    this.state.organization === "" ? (
                      <>
                        <Paper className={classes.paper}>
                          <Box>
                            <Box p={1} flexGrow={1} bgcolor="white">
                              <h6>
                                Find or create a Organization
                                <PersonAddIcon
                                  style={{
                                    float: "right",
                                    cursor: "pointer",
                                    color: SKY_BLUE,
                                  }}
                                  onClick={() =>
                                    this.handleClickOpen("AddCustomerModal")
                                  }
                                />
                              </h6>
                              <div style={{ marginLeft: "25px" }}>
                                <div style={{ marginTop: "20px" }}>
                                  <label>
                                    <input
                                      type="radio"
                                      name="vendortype"
                                      value="individual"
                                      checked={
                                        this.state.selectedOption ===
                                        "individual"
                                      }
                                      onChange={this.handleOptionChange}
                                      style={{ marginRight: "10px" }}
                                    />
                                    Individual
                                  </label>
                                  <label>
                                    <input
                                      type="radio"
                                      name="vendortype"
                                      value="organization"
                                      checked={
                                        this.state.selectedOption ===
                                        "organization"
                                      }
                                      onChange={this.handleOptionChange}
                                      style={{
                                        marginRight: "10px",
                                        marginLeft: "10px",
                                      }}
                                    />
                                    Organization
                                  </label>
                                </div>

                                <Box>
                                  <Popup
                                    title="Add Customer"
                                    contentTxt={<hr />}
                                    contentTxtBe={<hr />}
                                    btnVar="text"
                                    content={
                                      <AddCustomerModal
                                        onClickBtnClose={() =>
                                          this.handleClose("AddCustomerModal")
                                        }
                                        setCurrentCustomer={this.handleOnSelect}
                                      />
                                    } //component to send
                                    openPopup={this.state.addCustomerModal} //open
                                    closePop={() =>
                                      this.handleClose("AddCustomerModal")
                                    } //close
                                    onClickBtnOpen={() =>
                                      this.handleClickOpen("AddCustomerModal")
                                    } //on click open
                                    onClickBtnClose={() =>
                                      this.handleClose("AddCustomerModal")
                                    } //on click close
                                  />
                                </Box>
                              </div>

                              {this.state.selectedOption === "organization" && (
                                <ReactSearchAutocomplete
                                  items={[...this.props.g_organizations]}
                                  fuseOptions={{
                                    keys: ["fullName", "email", "tradingName"],
                                  }}
                                  resultStringKeyName="tradingName"
                                  onSearch={this.handleOnSearchCustomers}
                                  onSelect={(item) => {
                                    this.handleOnSelect(item, "organization");
                                  }}
                                />
                              )}

                              {this.state.selectedOption === "individual" && (
                                <ReactSearchAutocomplete
                                  items={[...this.props.g_individuals]}
                                  fuseOptions={{
                                    keys: ["fullName", "email", "tradingName"],
                                  }}
                                  resultStringKeyName="fullName"
                                  onSearch={this.handleOnSearchCustomers}
                                  onSelect={(item) => {
                                    this.handleOnSelect(item, "individual");
                                  }}
                                />
                              )}
                            </Box>
                          </Box>
                        </Paper>
                      </>
                    ) : (
                      ""
                    )}

                    {this.state.individuals !== "" ||
                    this.state.organization !== "" ? (
                      <>
                        <Paper
                          style={{ marginTop: "20px" }}
                          className={classes.paper}
                        >
                          {" "}
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              color: "red",
                            }}
                          >
                            <CancelIcon
                              onClick={() => {
                                this.setState({
                                  individuals: "",
                                  organization: "",
                                  currentCustomer: "",
                                });

                                console.log("HERE IS USER CLICKERRR");
                              }}
                            />
                          </Box>
                          <Box p={1}>
                            Vendor
                            <Box>
                              {this.state.individuals?.fullName ||
                                this.state.organization?.tradingName}
                            </Box>
                          </Box>
                          <hr />
                          <Box p={1}>
                            CONTACT INFORMATION
                            <Popup
                              title="Edit contact information"
                              btnText="Edit"
                              btnFloat="right"
                              marginTop="-30px"
                              btnColor="lightblue"
                              contentTxt={<hr />}
                              contentTxtBe={<hr />}
                              content={
                                <EditContactInfoModal
                                  updateContactInfo={this.updateContactInfo}
                                  onClickBtnClose={() =>
                                    this.handleClose("editInfoModal")
                                  }
                                  state={{
                                    note: this.state.editInfoModal,
                                    customer: this.state.currentCustomer,
                                  }}
                                />
                              }
                              openPopup={this.state.editInfoModal} //open
                              onClickBtnOpen={() =>
                                this.handleClickOpen("editInfoModal")
                              }
                              onClickBtnClose={() =>
                                this.handleClose("editInfoModal")
                              }
                            />
                            <Box>
                              {this.state.individuals &&
                                this.state.individuals.contactMediums[0]
                                  .mediumCharacteristics[0].emailAddress +
                                  "\n" +
                                  this.state.individuals.contactMediums[0]
                                    .mediumCharacteristics[0].country +
                                  "\n" +
                                  this.state.individuals.contactMediums[0]
                                    .mediumCharacteristics[0].number}
                              {this.state.organization &&
                                this.state.organization.contactMediums[0]
                                  .mediumCharacteristics[0].emailAddress +
                                  "\n" +
                                  this.state.organization.contactMediums[0]
                                    .mediumCharacteristics[0].country +
                                  "\n" +
                                  this.state.organization.contactMediums[0]
                                    .mediumCharacteristics[0].number}
                            </Box>
                          </Box>
                          {/* <Box p={1}>
                        BILLING ADDRESS
                        <Button
                          size="small"
                          variant="contained"
                          style={{
                            marginTop: "-10px",
                            float: "right",
                            textTransform: "none",
                          }}
                        >
                          Edit
                        </Button>
                        <Box>No billing address provided</Box>
                      </Box> */}
                        </Paper>
                      </>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
                {this?.state?.printReceipt && this?.state?.orderObject && (
                  <ThermalPrinterView
                    orderObject={this?.state?.orderObject}
                    customerObject={this.state?.currentCustomer}
                    receiptMetadata={this.props?.company_info?.receipt_metadata}
                    onFinish={this.onPrintFinish}
                  />
                )}
              </div>
            )}
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    g_orders: state.ordersState.orders,
    g_unfulfilled_orders: state.ordersState.unfulfilled_orders,
    g_unpaid_orders: state.ordersState.unpaid_orders,
    g_draft_orders: state.ordersState.draft_orders,
    g_open_orders: state.ordersState.open_orders,
    g_close_orders: state.ordersState.close_orders,
    g_active_products: state.productsState.active_products,
    g_active_customers: state.customersState.active_customers,
    global_config: state.globalState,
    g_discount_state: state.discountState,
    company_info: state.companyInfoState,
    g_vendors: state.vendorsState.vendors,
    g_individuals: state.individualsState.individuals,
    g_organizations: state.organizationsState.organizations,
    g_inventoryItems: state.inventoryState.inventoryItems,
  };
};
export default connect(
  mapStateToProps,
  null
)(withStyles(style)(PurchaseOrders));
