import { Dialog } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Barcode from "react-barcode";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { LIGHT_GREY, SKY_BLUE } from "../../colors/colors";
import helpers from "../../UtilitiesFunctions";
import { addCommasToNumber } from "../../utilities/CommaNumberFormatter";

export function ThermalPrinterView(props) {
  console.log(`Order Porps`, props);

  const contentToPrint = useRef(null);
  const history = useHistory();
  const [orderObject, updateOrderObject] = useState(props?.orderObject);
  const [customerObject, updateCustomerObject] = useState(
    props?.customerObject
  );
  const [receiptMetadata, updateReceiptMetadata] = useState(
    props?.receiptMetadata
  );

  const printDiv = () => {
    const content = contentToPrint.current.outerHTML;
    const contentHeight = content.offsetHeight - 500;
    const printWindow = window.open(
      "",
      `Receipt # ${orderObject?.orderId}`,
      `height=${3276},width=600`
    );

    if (!printWindow) {
      alert(
        "Please enable popups first; then click PRINT to print the receipt."
      );
      return;
    }

    printWindow.document.write(
      `<style>@media print { @page {size: 78mm 3276mm; margin: 0; } }</style>`
    );

    printWindow.document.write(
      `<html><head><title>Receipt # ${orderObject?.orderId}</title>`
    );
    printWindow.document.write(
      `<style>body { width: 74mm; height: 3276mm; padding: 0mm; }</style>`
    );
    printWindow.document.write("</head><body>");
    printWindow.document.write(content);
    printWindow.document.write("</body></html>");
    printWindow.document.close();

    printWindow.addEventListener("afterprint", () => {
      printWindow.close();
    });
    printWindow.onbeforeunload = function () {
      props?.onFinish();
    };

    printWindow.print();
  };

  useEffect(() => {
    console.log(`Order Porps`, orderObject, props?.orderObject, props);
    function printLoop() {
      if (contentToPrint.current) {
        console.log("Ref has current value:", contentToPrint.current);
        printDiv();
      } else {
        setTimeout(printLoop, 500);
      }
    }
    printLoop();
  }, [contentToPrint]);

  const styles = {
    th: { margin: 0, padding: 0, textAlign: "center" },
    td: { margin: 0, padding: 0, textAlign: "center" },
    tdLeft: {
      margin: 0,
      padding: 0,
      paddingTop: 2,
      paddingBottom: 2,
      textAlign: "left",
    },
    tdRight: { margin: 0, padding: 0, textAlign: "right" },
  };

  return (
    <Dialog open={true} keepMounted onClose={false}>
      <div
        id="print"
        ref={contentToPrint}
        style={{
          width: "64mm",
          border: "1px solid black",
          fontFamily: "sans-serif",
          fontSize: "12px",
        }}
      >
        <center>
          {!receiptMetadata ||
            (Object.keys(receiptMetadata).length === 0 && (
              <>
                <img
                  src="/logo1.png"
                  alt="LOGO"
                  style={{ width: "50%", marginTop: "10px" }}
                />
                <p>
                  <b>Hulm Solutions POS</b> <br />
                  support@hulmsolutions.com
                </p>
              </>
            ))}
          {receiptMetadata && Object.keys(receiptMetadata).length > 0 && (
            <>
              <img
                src={
                  receiptMetadata?.logoUrl
                    ? receiptMetadata?.logoUrl
                    : "/logo1.png"
                }
                alt="LOGO"
                style={{ width: "50%", marginTop: "10px" }}
              />
              <p>
                <b>{receiptMetadata?.businessName}</b>
                <br />
                {receiptMetadata?.businessAddress}
                <br />
                {receiptMetadata?.phoneNumber} <br />
                {receiptMetadata?.emailAddress}
              </p>
            </>
          )}
          {props.page === "POS" && props.bussinessType === "HEALTHCARE" && (
            <p style={{ padding: 0, margin: 0 }}>
              <hr />
              {helpers.convertTime(orderObject?.createdAt)}
              <br />
              <h5>
                TOKEN # <b>{orderObject?.orderId}</b>
              </h5>
            </p>
          )}

          {props.bussinessType === "FMCG" ||
            (props.page === "orderview" && (
              <p style={{ padding: 0, margin: 0 }}>
                <hr />
                {helpers.convertTime(orderObject?.createdAt)}
                <br />
                Order # {orderObject?.orderId}
              </p>
            ))}

          {props.bussinessType === "FMCG" && (
            <p>
              <hr style={{ padding: 0, margin: 0 }} />
              <b>SALES RECEIPT</b>
              <hr style={{ padding: 0, marginTop: 5, margin: 0 }} />
            </p>
          )}

          {props.bussinessType === "FMCG" && props.page === "orderview" && (
            <p>
              <hr style={{ padding: 0, margin: 0 }} />
              <b>SALES RECEIPT</b>
              <hr style={{ padding: 0, marginTop: 5, margin: 0 }} />
            </p>
          )}
        </center>
        <div>
          {props.bussinessType === "FMCG" ||
            (props.page === "orderview" && (
              <table style={{ width: "100%", fontSize: "12px" }}>
                <tr>
                  <th style={{ ...styles.th, ...{ width: "15%" } }}>Qty</th>
                  {/* <th style={{ ...styles.th, ...{ width: "45%" } }}>Item</th> */}
                  <th style={{ ...styles.th, ...{ width: "20%" } }}>Price</th>
                  <th style={{ ...styles.th, ...{ width: "20%" } }}>Tax(%)</th>
                  <th style={{ ...styles.th, ...{ width: "20%" } }}>Total</th>
                </tr>

                {orderObject?.lineItems?.map((object, index) => {
                  if (object?.returned) {
                    return;
                  }
                  return [
                    <tr style={{ height: 5 }}></tr>,
                    <tr>
                      <td
                        rowSpan={2}
                        style={{
                          ...styles.td,
                          ...{},
                        }}
                      >
                        {object?.quantity}
                      </td>
                      <td
                        colSpan={4}
                        style={{
                          ...styles.td,
                          ...{
                            wordBreak: "break-all",
                            borderBottom: "0.1px solid black",
                          },
                        }}
                      >
                        <span
                          style={{
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            WebkitLineClamp: 2,
                            lineClamp: 2,
                            maxHeight: "3em", // Adjust based on your line height
                          }}
                        >
                          {object?.productName}
                        </span>
                      </td>
                    </tr>,
                    <tr>
                      {/* <td style={{ ...styles.td, ...{} }}>{object?.quantity}</td>
                  <td
                    style={{ ...styles.tdLeft, ...{ wordBreak: "break-all" } }}
                  >
                    {object?.productName}
                  </td> */}

                      <td style={{ ...styles.td, ...{} }}>
                        {addCommasToNumber(object?.price)}
                      </td>
                      <td style={{ ...styles.td, ...{} }}>
                        {addCommasToNumber(
                          // ((object?.price * object?.quantity) / object?.price) * 100
                          object?.tax
                        )}
                      </td>
                      <td style={{ ...styles.tdRight, ...{} }}>
                        {addCommasToNumber(
                          // ((object?.price * object?.quantity) / object?.price) *
                          //   100 +
                          //   object?.price * object?.quantity
                          ((object?.tax / 100) * object?.price +
                            object?.price) *
                            object?.quantity
                        )}
                      </td>
                    </tr>,
                  ];
                })}
                <tr>
                  <td colSpan={5} style={{ ...styles.td, ...{} }}>
                    <hr style={{ padding: 0, margin: 0 }} />
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={3}
                    style={{ ...styles.tdRight, ...{ paddingRight: 3 } }}
                  >
                    Price Sum:
                  </td>

                  <td colSpan={2} style={{ ...styles.tdRight }}>
                    <b>{addCommasToNumber(orderObject?.subTotal)}</b>
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={3}
                    style={{ ...styles.tdRight, ...{ paddingRight: 3 } }}
                  >
                    Total Tax Amount:
                  </td>

                  <td colSpan={2} style={{ ...styles.tdRight }}>
                    <b>{addCommasToNumber(orderObject?.taxAmount)}</b>
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={3}
                    style={{ ...styles.tdRight, ...{ paddingRight: 3 } }}
                  >
                    Cumulative Tax %:
                  </td>

                  <td colSpan={3} style={{ ...styles.tdRight }}>
                    <b>{addCommasToNumber(orderObject?.taxRate)}</b>
                  </td>
                </tr>

                <tr>
                  <td
                    colSpan={3}
                    style={{
                      ...styles.tdRight,
                      ...{ paddingBottom: 10, paddingRight: 3 },
                    }}
                  >
                    Total Amount:
                  </td>

                  <td
                    colSpan={2}
                    style={{
                      ...styles.tdRight,
                      ...{ paddingBottom: 10 },
                    }}
                  >
                    <b>{addCommasToNumber(orderObject?.total)}</b>
                  </td>
                </tr>
                {orderObject?.discountAmount > 0 && (
                  <tr>
                    <td
                      colSpan={3}
                      style={{
                        ...styles.tdRight,
                        ...{ paddingRight: 3 },
                      }}
                    >
                      Discount Amount:
                    </td>

                    <td
                      colSpan={2}
                      style={{
                        ...styles.tdRight,
                      }}
                    >
                      <b>{addCommasToNumber(orderObject?.discountAmount)}</b>
                    </td>
                  </tr>
                )}
                <tr>
                  <td
                    colSpan={3}
                    style={{
                      ...styles.tdRight,
                      ...{ paddingRight: 3 },
                    }}
                  >
                    <b>Payable Amount:</b>
                  </td>

                  <td
                    colSpan={2}
                    style={{
                      ...styles.tdRight,
                    }}
                  >
                    <b>
                      {addCommasToNumber(
                        orderObject?.total -
                          (orderObject?.discountAmount
                            ? orderObject?.discountAmount
                            : 0)
                      )}
                    </b>
                  </td>
                </tr>
              </table>
            ))}

          {props.bussinessType === "FMCG" && (
            <table style={{ width: "100%", fontSize: "12px" }}>
              <tr>
                <th style={{ ...styles.th, ...{ width: "15%" } }}>Qty</th>
                {/* <th style={{ ...styles.th, ...{ width: "45%" } }}>Item</th> */}
                <th style={{ ...styles.th, ...{ width: "20%" } }}>Price</th>
                <th style={{ ...styles.th, ...{ width: "20%" } }}>Tax(%)</th>
                <th style={{ ...styles.th, ...{ width: "20%" } }}>Total</th>
              </tr>

              {orderObject?.lineItems?.map((object, index) => {
                if (object?.returned) {
                  return;
                }
                return [
                  <tr style={{ height: 5 }}></tr>,
                  <tr>
                    <td
                      rowSpan={2}
                      style={{
                        ...styles.td,
                        ...{},
                      }}
                    >
                      {object?.quantity}
                    </td>
                    <td
                      colSpan={4}
                      style={{
                        ...styles.td,
                        ...{
                          wordBreak: "break-all",
                          borderBottom: "0.1px solid black",
                        },
                      }}
                    >
                      <span
                        style={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          WebkitLineClamp: 2,
                          lineClamp: 2,
                          maxHeight: "3em", // Adjust based on your line height
                        }}
                      >
                        {object?.productName}
                      </span>
                    </td>
                  </tr>,
                  <tr>
                    {/* <td style={{ ...styles.td, ...{} }}>{object?.quantity}</td>
                  <td
                    style={{ ...styles.tdLeft, ...{ wordBreak: "break-all" } }}
                  >
                    {object?.productName}
                  </td> */}

                    <td style={{ ...styles.td, ...{} }}>
                      {addCommasToNumber(object?.price)}
                    </td>
                    <td style={{ ...styles.td, ...{} }}>
                      {addCommasToNumber(
                        // ((object?.price * object?.quantity) / object?.price) * 100
                        object?.tax
                      )}
                    </td>
                    <td style={{ ...styles.tdRight, ...{} }}>
                      {addCommasToNumber(
                        // ((object?.price * object?.quantity) / object?.price) *
                        //   100 +
                        //   object?.price * object?.quantity
                        ((object?.tax / 100) * object?.price + object?.price) *
                          object?.quantity
                      )}
                    </td>
                  </tr>,
                ];
              })}
              <tr>
                <td colSpan={5} style={{ ...styles.td, ...{} }}>
                  <hr style={{ padding: 0, margin: 0 }} />
                </td>
              </tr>
              <tr>
                <td
                  colSpan={3}
                  style={{ ...styles.tdRight, ...{ paddingRight: 3 } }}
                >
                  Price Sum:
                </td>

                <td colSpan={2} style={{ ...styles.tdRight }}>
                  <b>{addCommasToNumber(orderObject?.subTotal)}</b>
                </td>
              </tr>
              <tr>
                <td
                  colSpan={3}
                  style={{ ...styles.tdRight, ...{ paddingRight: 3 } }}
                >
                  Total Tax Amount:
                </td>

                <td colSpan={2} style={{ ...styles.tdRight }}>
                  <b>{addCommasToNumber(orderObject?.taxAmount)}</b>
                </td>
              </tr>
              <tr>
                <td
                  colSpan={3}
                  style={{ ...styles.tdRight, ...{ paddingRight: 3 } }}
                >
                  Cumulative Tax %:
                </td>

                <td colSpan={3} style={{ ...styles.tdRight }}>
                  <b>{addCommasToNumber(orderObject?.taxRate)}</b>
                </td>
              </tr>

              <tr>
                <td
                  colSpan={3}
                  style={{
                    ...styles.tdRight,
                    ...{ paddingBottom: 10, paddingRight: 3 },
                  }}
                >
                  Total Amount:
                </td>

                <td
                  colSpan={2}
                  style={{
                    ...styles.tdRight,
                    ...{ paddingBottom: 10 },
                  }}
                >
                  <b>{addCommasToNumber(orderObject?.total)}</b>
                </td>
              </tr>
              {orderObject?.discountAmount > 0 && (
                <tr>
                  <td
                    colSpan={3}
                    style={{
                      ...styles.tdRight,
                      ...{ paddingRight: 3 },
                    }}
                  >
                    Discount Amount:
                  </td>

                  <td
                    colSpan={2}
                    style={{
                      ...styles.tdRight,
                    }}
                  >
                    <b>{addCommasToNumber(orderObject?.discountAmount)}</b>
                  </td>
                </tr>
              )}
              <tr>
                <td
                  colSpan={3}
                  style={{
                    ...styles.tdRight,
                    ...{ paddingRight: 3 },
                  }}
                >
                  <b>Payable Amount:</b>
                </td>

                <td
                  colSpan={2}
                  style={{
                    ...styles.tdRight,
                  }}
                >
                  <b>
                    {addCommasToNumber(
                      orderObject?.total -
                        (orderObject?.discountAmount
                          ? orderObject?.discountAmount
                          : 0)
                    )}
                  </b>
                </td>
              </tr>
            </table>
          )}

          {customerObject !== "" && (
            <>
              <center>
                <p>
                  <hr style={{ padding: 0, marginBottom: 5, margin: 0 }} />
                  <b>Customer Information</b>
                  <hr style={{ padding: 0, marginTop: 5, margin: 0 }} />
                </p>
              </center>
              <table
                style={{ textAlign: "left", width: "100%", fontSize: "12px" }}
              >
                <tr>
                  <td style={{ ...styles.tdLeft, ...{ width: "20%" } }}>
                    Name
                  </td>
                  <td style={{ ...styles.tdLeft, ...{ width: "5%" } }}>:</td>
                  <td style={{ ...styles.tdLeft, ...{ width: "75%" } }}>
                    {customerObject?.name}
                  </td>
                </tr>
                <tr>
                  <td style={{ ...styles.tdLeft, ...{ width: "20%" } }}>
                    Contact
                  </td>
                  <td style={{ ...styles.tdLeft, ...{ width: "5%" } }}>:</td>
                  <td style={{ ...styles.tdLeft, ...{ width: "75%" } }}>
                    {customerObject?.phoneNumber}
                  </td>
                </tr>
                <tr>
                  <td style={{ ...styles.tdLeft, ...{ width: "20%" } }}>
                    Email
                  </td>
                  <td style={{ ...styles.tdLeft, ...{ width: "5%" } }}>:</td>
                  <td style={{ ...styles.tdLeft, ...{ width: "75%" } }}>
                    {customerObject?.email}
                  </td>
                </tr>
              </table>
            </>
          )}
        </div>

        {props.bussinessType === "FMCG" ||
          (props.page === "orderview" && (
            <center>
              <p>
                <hr
                  style={{
                    padding: 0,
                    marginBottom: 5,
                    margin: 0,
                    marginTop: 10,
                  }}
                />
                <b>Terms and Conditions</b>
                <hr style={{ padding: 0, marginTop: 5, margin: 0 }} />
              </p>
            </center>
          ))}

        {props.bussinessType === "FMCG" && (
          <center>
            <p>
              <hr
                style={{
                  padding: 0,
                  marginBottom: 5,
                  margin: 0,
                  marginTop: 10,
                }}
              />
              <b>Terms and Conditions</b>
              <hr style={{ padding: 0, marginTop: 5, margin: 0 }} />
            </p>
          </center>
        )}

        {props.bussinessType === "FMCG" ||
          (props.page === "orderview" && (
            <ol
              style={{
                fontSize: "10px",
                paddingRight: "1mm",
                paddingLeft: "3.5mm",
                textAlign: "justify",
              }}
            >
              <li>
                <strong>Return Policy:</strong> Items may be returned within a
                specified timeframe, typically with a receipt and in original
                condition, for a refund, exchange, or store credit. Some
                restrictions may apply.
              </li>

              <li>
                <strong>Warranty Information:</strong> Products may be covered
                by a manufacturer's warranty; details on warranty coverage and
                how to make a claim should be provided.
              </li>

              <li>
                <strong>Usage Restrictions:</strong> Certain items may have
                limitations on their use, such as age restrictions for
                purchasing certain products, or restrictions on the use of
                coupons or discounts.
              </li>

              <li>
                <strong>Liability Disclaimer:</strong> The store may not be
                liable for damages or injuries resulting from the use of
                purchased items, misuse, or improper installation.
              </li>

              <li>
                <strong>Privacy Policy:</strong> Information collected during
                transactions, such as personal details or payment information,
                may be subject to the store's privacy policy. This typically
                includes assurances of data protection and may outline how
                information is used and shared.
              </li>
            </ol>
          ))}

        {props.bussinessType === "FMCG" && (
          <ol
            style={{
              fontSize: "10px",
              paddingRight: "1mm",
              paddingLeft: "3.5mm",
              textAlign: "justify",
            }}
          >
            <li>
              <strong>Return Policy:</strong> Items may be returned within a
              specified timeframe, typically with a receipt and in original
              condition, for a refund, exchange, or store credit. Some
              restrictions may apply.
            </li>

            <li>
              <strong>Warranty Information:</strong> Products may be covered by
              a manufacturer's warranty; details on warranty coverage and how to
              make a claim should be provided.
            </li>

            <li>
              <strong>Usage Restrictions:</strong> Certain items may have
              limitations on their use, such as age restrictions for purchasing
              certain products, or restrictions on the use of coupons or
              discounts.
            </li>

            <li>
              <strong>Liability Disclaimer:</strong> The store may not be liable
              for damages or injuries resulting from the use of purchased items,
              misuse, or improper installation.
            </li>

            <li>
              <strong>Privacy Policy:</strong> Information collected during
              transactions, such as personal details or payment information, may
              be subject to the store's privacy policy. This typically includes
              assurances of data protection and may outline how information is
              used and shared.
            </li>
          </ol>
        )}

        <hr style={{ padding: 0, marginBottom: 5, margin: 0, marginTop: 10 }} />
        <center>
          <Barcode
            value={orderObject?.orderId}
            height={50}
            width={2}
            fontSize={15}
          />
          <p style={{ fontSize: 20, padding: 0, margin: 0 }}>Thank You!</p>

          <p
            style={{
              fontSize: "10px",
              textAlign: "center",
            }}
          >
            ------------------------------------- <br /> Powered By:{" "}
            <b>Hulm Solutions</b> <br />
            sales@hulmsolutions.com
          </p>
        </center>
      </div>
      <center>
        <div
          style={{
            margin: 10,
            justifyContent: "space-around",
            display: "flex",
          }}
        >
          <button
            className="stripe-button"
            style={{
              // marginLeft: "3%",
              backgroundColor: SKY_BLUE,
            }}
            onClick={() => {
              printDiv();
              //handlePrint(null, () => contentToPrint.current);
            }}
          >
            Print
          </button>

          <button
            className="stripe-button"
            style={{
              // marginLeft: "3%",
              backgroundColor: LIGHT_GREY,
            }}
            onClick={() => {
              props?.onFinish();
              //handlePrint(null, () => contentToPrint.current);
            }}
          >
            Close
          </button>
        </div>
      </center>
    </Dialog>
  );
}
