import CustomProduct from "./CustomProduct";
const CustomProducts = ({
  products,
  onDelete,
  onToggle,
  isDelete,
  getQty,
  disableQty,
  isRefund,
  isEdit,
  updatePrice,
  isPriceUpdateable,
  inventory,
}) => {
  console.log("-----------------------------", products);
  return (
    <>
      {products.map((product, index) => {
        console.log("-----------------------------", product);
        return (
          <CustomProduct
            key={index}
            product={product}
            onDelete={onDelete}
            onToggle={onToggle}
            isDelete={isDelete}
            getQty={getQty}
            disableQty={disableQty}
            isRefund={isRefund}
            isEdit={isEdit}
            updatePrice={updatePrice}
            isPriceUpdateable={isPriceUpdateable}
          />
        );
      })}
    </>
  );
};

export default CustomProducts;
