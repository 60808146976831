import React, { useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AirportShuttleSharpIcon from "@material-ui/icons/AirportShuttleSharp";
import ViewModuleSharpIcon from "@material-ui/icons/ViewModuleSharp";
import PostAddSharpIcon from "@material-ui/icons/PostAddSharp";
import RestoreFromTrashSharpIcon from "@material-ui/icons/RestoreFromTrashSharp";
import GroupAddSharpIcon from "@material-ui/icons/GroupAddSharp";
import LocalAtmSharpIcon from "@material-ui/icons/LocalAtmSharp";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Switch, Route, Link, Redirect, useLocation } from "react-router-dom";
import Customers from "../components/Customers/Customers";
import PosContainer from "../containers/PosContainer/PosContainer";
import ProductList from "../Pages/Products/productList";
import Inventory from "../Pages/Inventory/Inventory";
import EditOrder from "../components/Order/EditOrder";
import SalesOverTime from "../components/Analytics/SalesOverTime";
import MenuItem from "@material-ui/core/MenuItem";
import Orders from "../Pages/CreateOrders/Orders";
import ViewOrders from "../Pages/Orders/ViewOrders";
import Settings from "../components/Settings/settings";
import Refund from "../components/Order/Refund";
import ViewOrder from "../Pages/OrderDetail/ViewOrder";
import MarkAsFulfilled from "../components/Order/MarkAsFulfilled";
import Discounts from "../components/Discounts/Discount";
import AutomatedDiscount from "../components/Discounts/AutomatedDisccount";
import AddCustomerForm from "../components/Customers/AddCustomerForm";
import AddProductForm from "../components/Products/AddProductForm";
import EditProduct from "../components/Products/EditProduct";
import NotFound from "../Pages/NotFound/NotFound";
import TextEditor from "../components/Editor/TextEditor";
import Profile from "../components/Profile/profile";
import Variant from "../components/Products/variant";
import EditCustomer from "../components/Customers/Editcustomer";
import EditDiscount from "../components/Discounts/EditDiscount";
import InventoryHistory from "../components/InventoryHistory";
import DiscountCodesForm from "../components/Discounts/DiscountCodesForm";
import AutomatedDiscountForm from "../components/Discounts/AutomatedDiscountForm";
import EditVariant from "../components/Products/EditVariant";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import AnalyticsDashboard from "../components/Analytics/AnalyticsDashboard";
import SalesByProductOverTime from "../components/Analytics/SalesByProductOverTime";
import OrdersOverTime from "../components/Analytics/OrdersOverTime";
import FulfilledOrdersOverTime from "../components/Analytics/FulfilledOrdersOverTime";
import PercentOfInventorySold from "../components/Analytics/PercentOfInventorySold";
import CustomersOverTime from "../components/Analytics/CustomersOverTime";
import ReturningCustomersOverTime from "../components/Analytics/ReturningCustomersOverTime";
import OneTimeCustomersOverTime from "../components/Analytics/OneTimeCustomersOverTime";
import AverageInventorySoldOverTime from "../components/Analytics/AverageInventorySoldOverTime";
import ProductOrderAndReturnOverTime from "../components/Analytics/ProductOrderAndReturnOverTime";
import ProfitByProductOverTime from "../components/Analytics/ProfitByProductOverTime";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import UserManagement from "../components/User Management/UserManagement";
import Checkout from "../components/stripe/checkout";
import EditAutomdatedDiscount from "../components/Discounts/EditAutomdatedDiscount";
import CustomersAllOrders from "../components/Customers/CustomersAllOrders";
import Menu from "@material-ui/core/Menu";
import ProductDetails from "../components/Products/ProductDetails";
import VariantDetails from "../components/Products/ProductVariants";
import { SKY_BLUE, LIGHT_GREY } from "../colors/colors";
import DesktopAppBar from "../components/Navbar/AppBar";
import CancelOrder from "../components/Order/CancelOrder";
import OrderStatus from "../components/Order/OrderStatus";
import KeycloackForm from "../components/KeycloackForm";
import Store from "../index";
import { useSelector } from "react-redux";
import {
  ShoppingBasketOutlined,
  StorefrontOutlined,
} from "@mui/icons-material";
import PurchaseOrders from "../Pages/PurchaseOrders/PurchaseOrders";
import Vendors from "../Pages/Vendors/Vendors";
import AddVendor from "../Pages/Vendors/AddVendor";
import ViewPurchaseOrder from "../Pages/ViewPurchaseOrder/ViewPurchaseOrder";
const drawerWidth = 260;
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "rgb(39, 136, 6)",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
        textDecoration: "none",
      },
    },
  },
}))(MenuItem);
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    textDecoration: "none",
  },
  drawer: {
    marginTop: "30px",
    textDecoration: "none",
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  hideSearch: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  appBar: {
    backgroundColor: "rgb(39, 136, 6)",

    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
      width: "100%",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,

    paddingTop: "56px",
    zIndex: 1,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    margin: theme.spacing(1),

    [theme.breakpoints.down("sm")]: {
      margin: 0,

      padding: 0,
    },
  },
  mecuIcon: {
    textDecoration: "none",
    fontWeight: "bold",
    marginTop: "5px",

    color: "#000",

    "&:hover": {
      backgroundColor: SKY_BLUE,

      color: "white",
      textDecoration: "none",
    },
  },
  draweMenu: {
    textDecoration: "none",

    fontWeight: "bold",
    fontSize: "20px",

    marginTop: "5px",

    color: "#000",

    "&:hover": {
      backgroundColor: SKY_BLUE,
      color: "white",
      textDecoration: "none",
    },
  },
  activeLink: {
    backgroundColor: SKY_BLUE,
    color: "white",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

function ResponsiveDrawer(props) {
  const location = useLocation();

  const isActive = (path) => {
    console.log("here is data from path", path === "/POS" ? cartlength : "");
    return location.pathname === path;
  };

  // console.log("here is apptype inside response drwaer", props.appType);

  const { window, keycloak, token, userInfo, cartlength, bussinesType } = props;
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [Open, setOpen] = React.useState(false);
  const [appType, setappType] = useState(props.appType);
  const [anchorEl, setAnchorEl] = React.useState(null);

  console.log("here is bussinesstype", bussinesType);

  // console.log("here is apptype inside response drwaer", appType);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleDrawerToggleOnClick = () => {
    setOpen(!Open);
  };

  let clientId = keycloak.clientId;
  let rolee = "";
  console.log(keycloak?.resourceAccess);
  console.log(keycloak?.resourceAccess);
  if (keycloak.resourceAccess != undefined) {
    Object.entries(keycloak?.resourceAccess).forEach((a) => {
      if (a[0] == clientId) {
        console.log(a[1].roles[0]);
        rolee = a[1].roles;
      }
    });
  }

  var isAdmin =
    keycloak.tokenParsed?.realm_access?.roles.filter(function (val) {
      return val === "Admin";
    }).length > 0 || rolee.includes("Admin");
  var isManager =
    keycloak.tokenParsed?.realm_access?.roles.filter(function (val) {
      return val === "manager";
    }).length > 0 || rolee.includes("manager");
  var isAgent =
    keycloak.tokenParsed?.realm_access?.roles.filter(function (val) {
      return val === "agent" || rolee.includes("agent");
    }).length > 0;
  console.log("Admin", isAdmin);
  console.log("Manager", isManager);
  console.log("Agent", isAgent);

  const drawer = (
    <div>
      <div
        style={{
          height: "60px",
          marginTop: "-60px",
          padding: "0px",
          width: "250px",
          backgroundColor: "rgba(16,172,74)",
          color: "white",
        }}
      ></div>

      {/* All Admin------------------------------------------------------------------- */}
      {keycloak &&
        keycloak?.resourceAccess?.instance &&
        isAdmin &&
        appType === "HPOS-" &&
        bussinesType === "HEALTHCARE" && (
          <List>
            {[
              {
                name: "Sales Order",
                icon: <AirportShuttleSharpIcon />,
              },
              {
                name: "Purchase Orders",
                icon: <ShoppingBasketOutlined />,
              },
              { name: "Products", icon: <PostAddSharpIcon /> },
              { name: "Inventory", icon: <RestoreFromTrashSharpIcon /> },
              { name: "POS", icon: <LocalAtmSharpIcon /> },
              { name: "Customers", icon: <GroupAddSharpIcon /> },
              { name: "Users", icon: <GroupAddIcon /> },
              { name: "Vendors", icon: <StorefrontOutlined /> },
            ].map((list, index) => (
              <Link
                to={"/" + list.name}
                className={classes.draweMenu}
                key={index}
              >
                <ListItem
                  className={`${classes.drawerMenu} ${
                    isActive(`/${list.name}`) ? classes.activeLink : ""
                  }`}
                  key={index}
                  component={Link}
                  active="true"
                  style={{ textDecoration: "none", color: "black" }}
                  to={"/" + list.name}
                >
                  <ListItemIcon className={classes.MenuIcon}>
                    {list.icon}
                  </ListItemIcon>
                  <ListItemText
                    style={{ textDecoration: "none" }}
                    primary={list.name}
                  />
                  {list.name === "POS" && (
                    <ListItemText style={{ textDecoration: "none" }}>
                      {location.pathname !== "/POS" && cartlength > 0
                        ? `(${cartlength})`
                        : ""}
                    </ListItemText>
                  )}
                </ListItem>
              </Link>
            ))}
          </List>
        )}

      {/* All Admin------------------------------------------------------------------- */}
      {keycloak &&
        keycloak?.resourceAccess?.instance &&
        isAdmin &&
        appType === "HPOS-" &&
        bussinesType === "FMCG" && (
          <List>
            {[
              {
                name: "Sales Order",
                icon: <AirportShuttleSharpIcon />,
              },
              {
                name: "Purchase Orders",
                icon: <ShoppingBasketOutlined />,
              },
              { name: "Products", icon: <PostAddSharpIcon /> },
              { name: "Inventory", icon: <RestoreFromTrashSharpIcon /> },
              { name: "POS", icon: <LocalAtmSharpIcon /> },
              { name: "Customers", icon: <GroupAddSharpIcon /> },
              { name: "Discount", icon: <ViewModuleSharpIcon /> },
              { name: "Analytics", icon: <ShowChartIcon /> },
              { name: "Users", icon: <GroupAddIcon /> },
              { name: "Vendors", icon: <StorefrontOutlined /> },
            ].map((list, index) => (
              <Link
                to={"/" + list.name}
                className={classes.draweMenu}
                key={index}
              >
                <ListItem
                  className={`${classes.drawerMenu} ${
                    isActive(`/${list.name}`) ? classes.activeLink : ""
                  }`}
                  key={index}
                  component={Link}
                  active="true"
                  style={{ textDecoration: "none", color: "black" }}
                  to={"/" + list.name}
                >
                  <ListItemIcon className={classes.MenuIcon}>
                    {list.icon}
                  </ListItemIcon>
                  <ListItemText
                    style={{ textDecoration: "none" }}
                    primary={list.name}
                  />
                  {list.name === "POS" && (
                    <ListItemText style={{ textDecoration: "none" }}>
                      {location.pathname !== "/POS" && cartlength > 0
                        ? `(${cartlength})`
                        : ""}
                    </ListItemText>
                  )}
                </ListItem>
              </Link>
            ))}
          </List>
        )}

      {/* Admin HPRMS */}
      {keycloak &&
        keycloak?.resourceAccess?.instance &&
        isAdmin &&
        appType === "HPRMS-" && (
          <List>
            {[
              {
                name: "Purchase Orders",
                icon: <ShoppingBasketOutlined />,
              },
              { name: "Products", icon: <PostAddSharpIcon /> },
              { name: "Inventory", icon: <RestoreFromTrashSharpIcon /> },
              { name: "Vendors", icon: <StorefrontOutlined /> },
            ].map((list, index) => (
              <Link
                to={"/" + list.name}
                className={classes.draweMenu}
                key={index}
              >
                <ListItem
                  className={`${classes.drawerMenu} ${
                    isActive(`/${list.name}`) ? classes.activeLink : ""
                  }`}
                  key={index}
                  component={Link}
                  active="true"
                  style={{ textDecoration: "none", color: "black" }}
                  to={"/" + list.name}
                >
                  <ListItemIcon className={classes.MenuIcon}>
                    {list.icon}
                  </ListItemIcon>
                  <ListItemText
                    style={{ textDecoration: "none" }}
                    primary={list.name}
                  />
                  {list.name === "POS" && (
                    <ListItemText style={{ textDecoration: "none" }}>
                      {location.pathname !== "/POS" && cartlength > 0
                        ? `(${cartlength})`
                        : ""}
                    </ListItemText>
                  )}
                </ListItem>
              </Link>
            ))}
          </List>
        )}

      {/* ADMIN HCRM */}
      {keycloak &&
        keycloak?.resourceAccess?.instance &&
        isAdmin &&
        appType === "HCRM-" && (
          <List>
            {[{ name: "Customers", icon: <GroupAddSharpIcon /> }].map(
              (list, index) => (
                <Link
                  to={"/" + list.name}
                  className={classes.draweMenu}
                  key={index}
                >
                  <ListItem
                    className={`${classes.drawerMenu} ${
                      isActive(`/${list.name}`) ? classes.activeLink : ""
                    }`}
                    key={index}
                    component={Link}
                    active="true"
                    style={{ textDecoration: "none", color: "black" }}
                    to={"/" + list.name}
                  >
                    <ListItemIcon className={classes.MenuIcon}>
                      {list.icon}
                    </ListItemIcon>
                    <ListItemText
                      style={{ textDecoration: "none" }}
                      primary={list.name}
                    />
                    {list.name === "POS" && (
                      <ListItemText style={{ textDecoration: "none" }}>
                        {location.pathname !== "/POS" && cartlength > 0
                          ? `(${cartlength})`
                          : ""}
                      </ListItemText>
                    )}
                  </ListItem>
                </Link>
              )
            )}
          </List>
        )}

      {/* ADMIN HPIMS */}

      {keycloak &&
        keycloak?.resourceAccess?.instance &&
        isAdmin &&
        appType === "HPiMS-" && (
          <List>
            {[
              { name: "Products", icon: <PostAddSharpIcon /> },
              { name: "Inventory", icon: <RestoreFromTrashSharpIcon /> },
            ].map((list, index) => (
              <Link
                to={"/" + list.name}
                className={classes.draweMenu}
                key={index}
              >
                <ListItem
                  className={`${classes.drawerMenu} ${
                    isActive(`/${list.name}`) ? classes.activeLink : ""
                  }`}
                  key={index}
                  component={Link}
                  active="true"
                  style={{ textDecoration: "none", color: "black" }}
                  to={"/" + list.name}
                >
                  <ListItemIcon className={classes.MenuIcon}>
                    {list.icon}
                  </ListItemIcon>
                  <ListItemText
                    style={{ textDecoration: "none" }}
                    primary={list.name}
                  />
                  {list.name === "POS" && (
                    <ListItemText style={{ textDecoration: "none" }}>
                      {location.pathname !== "/POS" && cartlength > 0
                        ? `(${cartlength})`
                        : ""}
                    </ListItemText>
                  )}
                </ListItem>
              </Link>
            ))}
          </List>
        )}

      {/* ADMIN HOMS */}
      {keycloak &&
        keycloak?.resourceAccess?.instance &&
        isAdmin &&
        appType === "HOMS-" && (
          <List>
            {[
              {
                name: "Sales Order",
                icon: <AirportShuttleSharpIcon />,
              },
              { name: "Products", icon: <PostAddSharpIcon /> },
              { name: "Inventory", icon: <RestoreFromTrashSharpIcon /> },
              { name: "Customers", icon: <GroupAddSharpIcon /> },
            ].map((list, index) => (
              <Link
                to={"/" + list.name}
                className={classes.draweMenu}
                key={index}
              >
                <ListItem
                  className={`${classes.drawerMenu} ${
                    isActive(`/${list.name}`) ? classes.activeLink : ""
                  }`}
                  key={index}
                  component={Link}
                  active="true"
                  style={{ textDecoration: "none", color: "black" }}
                  to={"/" + list.name}
                >
                  <ListItemIcon className={classes.MenuIcon}>
                    {list.icon}
                  </ListItemIcon>
                  <ListItemText
                    style={{ textDecoration: "none" }}
                    primary={list.name}
                  />
                  {list.name === "POS" && (
                    <ListItemText style={{ textDecoration: "none" }}>
                      {location.pathname !== "/POS" && cartlength > 0
                        ? `(${cartlength})`
                        : ""}
                    </ListItemText>
                  )}
                </ListItem>
              </Link>
            ))}
          </List>
        )}

      {/* ADMIN HRMS */}
      {keycloak &&
        keycloak?.resourceAccess?.instance &&
        isAdmin &&
        appType === "HRMS-" && (
          <List>
            {[{ name: "Analytics", icon: <ShowChartIcon /> }].map(
              (list, index) => (
                <Link
                  to={"/" + list.name}
                  className={classes.draweMenu}
                  key={index}
                >
                  <ListItem
                    className={`${classes.drawerMenu} ${
                      isActive(`/${list.name}`) ? classes.activeLink : ""
                    }`}
                    key={index}
                    component={Link}
                    active="true"
                    style={{ textDecoration: "none", color: "black" }}
                    to={"/" + list.name}
                  >
                    <ListItemIcon className={classes.MenuIcon}>
                      {list.icon}
                    </ListItemIcon>
                    <ListItemText
                      style={{ textDecoration: "none" }}
                      primary={list.name}
                    />
                    {list.name === "POS" && (
                      <ListItemText style={{ textDecoration: "none" }}>
                        {location.pathname !== "/POS" && cartlength > 0
                          ? `(${cartlength})`
                          : ""}
                      </ListItemText>
                    )}
                  </ListItem>
                </Link>
              )
            )}
          </List>
        )}

      {/* Admin HVMS */}
      {keycloak &&
        keycloak?.resourceAccess?.instance &&
        isAdmin &&
        appType === "HVMS-" && (
          <List>
            {[{ name: "Vendors", icon: <StorefrontOutlined /> }].map(
              (list, index) => (
                <Link
                  to={"/" + list.name}
                  className={classes.draweMenu}
                  key={index}
                >
                  <ListItem
                    className={`${classes.drawerMenu} ${
                      isActive(`/${list.name}`) ? classes.activeLink : ""
                    }`}
                    key={index}
                    component={Link}
                    active="true"
                    style={{ textDecoration: "none", color: "black" }}
                    to={"/" + list.name}
                  >
                    <ListItemIcon className={classes.MenuIcon}>
                      {list.icon}
                    </ListItemIcon>
                    <ListItemText
                      style={{ textDecoration: "none" }}
                      primary={list.name}
                    />
                    {list.name === "POS" && (
                      <ListItemText style={{ textDecoration: "none" }}>
                        {location.pathname !== "/POS" && cartlength > 0
                          ? `(${cartlength})`
                          : ""}
                      </ListItemText>
                    )}
                  </ListItem>
                </Link>
              )
            )}
          </List>
        )}

      {keycloak &&
        keycloak.resourceAccess.instance &&
        isManager &&
        !isAdmin && (
          <List>
            {[
              {
                name: "Sales Order",
                icon: <AirportShuttleSharpIcon />,
              },
              { name: "Products", icon: <PostAddSharpIcon /> },
              { name: "Inventory", icon: <RestoreFromTrashSharpIcon /> },

              { name: "Customers", icon: <GroupAddSharpIcon /> },
              { name: "Discount", icon: <ViewModuleSharpIcon /> },
              { name: "Analytics", icon: <ShowChartIcon /> },
            ].map((list, index) => (
              <Link
                to={"/" + list.name}
                className={classes.draweMenu}
                key={index}
              >
                <ListItem
                  className={classes.draweMenu}
                  key={index}
                  component={Link}
                  active="true"
                  to={"/" + list.name}
                >
                  <ListItemIcon>{list.icon}</ListItemIcon>
                  <ListItemText primary={list.name} />
                </ListItem>
              </Link>
            ))}
          </List>
        )}

      {keycloak &&
        keycloak.resourceAccess.instance &&
        isAgent &&
        !(isAdmin && isManager) && (
          <List>
            {[{ name: "POS", icon: <LocalAtmSharpIcon /> }].map(
              (list, index) => (
                <Link
                  to={"/" + list.name}
                  className={classes.draweMenu}
                  key={index}
                  // onTouchTap={this.handleClose}
                >
                  <ListItem
                    className={classes.draweMenu}
                    key={index}
                    component={Link}
                    active="true"
                    to={"/" + list.name}
                  >
                    <ListItemIcon>{list.icon}</ListItemIcon>
                    <ListItemText primary={list.name} />
                  </ListItem>
                </Link>
              )
            )}
          </List>
        )}
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  const { authenticated } = keycloak;
  return (
    <>
      {/* {!authenticated ? (
        <div className="blobcontainer">
          <div class="shapes"></div>
        </div>
      ) : ( */}
      <div className={classes.root}>
        <CssBaseline />
        <DesktopAppBar
          keycloak={keycloak}
          authenticated={authenticated}
          userInfo={userInfo}
        />

        {keycloak && keycloak?.resourceAccess?.instance && isAdmin && (
          <nav className={classes.drawer} aria-label="mailbox folders">
            <Hidden smUp implementation="css">
              <Drawer
                onClick={handleDrawerToggleOnClick}
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden xsDown smDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open={handleDrawerToggleOnClick}
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
        )}

        {keycloak &&
          keycloak?.resourceAccess?.instance &&
          isAgent &&
          !isAdmin &&
          !isManager && (
            <nav className={classes.drawer} aria-label="mailbox folders">
              <Hidden smUp implementation="css">
                <Drawer
                  onClick={handleDrawerToggleOnClick}
                  container={container}
                  variant="temporary"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                >
                  {drawer}
                </Drawer>
              </Hidden>
              <Hidden xsDown smDown implementation="css">
                <Drawer
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  variant="permanent"
                  open={handleDrawerToggleOnClick}
                >
                  {drawer}
                </Drawer>
              </Hidden>
            </nav>
          )}

        {keycloak &&
          keycloak.resourceAccess.instance &&
          isManager &&
          !isAdmin && (
            <nav className={classes.drawer} aria-label="mailbox folders">
              <Hidden smUp implementation="css">
                <Drawer
                  onClick={handleDrawerToggleOnClick}
                  container={container}
                  variant="temporary"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                >
                  {drawer}
                </Drawer>
              </Hidden>
              <Hidden xsDown smDown implementation="css">
                <Drawer
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  variant="permanent"
                  open={handleDrawerToggleOnClick}
                >
                  {drawer}
                </Drawer>
              </Hidden>
            </nav>
          )}

        <main className={classes.content}>
          <div className={classes.toolbar} />

          {keycloak &&
            keycloak.resourceAccess.instance &&
            isManager &&
            !isAdmin && (
              <Switch>
                <Route
                  path="/"
                  exact
                  render={(props) => <ProductList {...props} />}
                />
                <Route
                  path="/Sales Order"
                  // render={(props) => <Orders {...props} />}
                  render={(props) => <ViewOrders {...props} />}
                />
                <Route
                  path="/CreateOrders"
                  render={(props) => <Orders {...props} />}
                  // render={(props) => <ViewOrders {...props} />}
                />
                <Route
                  path="/order/status/:id"
                  render={(props) => <OrderStatus {...props} />}
                />
                <Route
                  path="/ViewOrders"
                  render={(props) => <ViewOrders {...props} />}
                />
                <Route
                  path="/order/cancel/:id"
                  render={(props) => <CancelOrder {...props} />}
                />
                <Route
                  path="/Discount"
                  render={(props) => <Discounts {...props} />}
                />
                <Route
                  path="/AutomatedDiscount"
                  render={(props) => <AutomatedDiscount {...props} />}
                />
                <Route
                  path="/editdiscount"
                  render={(props) => <EditDiscount {...props} />}
                />
                <Route
                  path="/ViewOrder"
                  render={(props) => <ViewOrder {...props} />}
                />
                <Route
                  path="/Customers"
                  render={(props) => <Customers {...props} />}
                />
                {/* <Route path="/Pos" render={(props) => <PosContainer {...props} />} /> */}
                <Route
                  path="/Products"
                  render={(props) => <ProductList {...props} />}
                />
                <Route
                  path="/inventory"
                  render={(props) => <Inventory {...props} />}
                />
                <Route
                  path="/Analytics"
                  render={(props) => <AnalyticsDashboard {...props} />}
                />
                <Route
                  path="/ProductForm"
                  render={(props) => <AddProductForm {...props} />}
                />
                <Route
                  path="/editcustomer"
                  render={(props) => <EditCustomer {...props} />}
                />
                <Route
                  path="/customer"
                  render={(props) => <AddCustomerForm {...props} />}
                />
                <Route
                  path="/discountCode"
                  render={(props) => <DiscountCodesForm {...props} />}
                />
                <Route
                  exact
                  path="/DiscountautomatedForm"
                  render={(props) => <AutomatedDiscountForm {...props} />}
                />
                <Route
                  path="/edit"
                  render={(props) => <EditOrder {...props} />}
                />
                <Route
                  path="/refund"
                  render={(props) => <Refund {...props} />}
                />
                <Route
                  path="/fulfill"
                  render={(props) => <MarkAsFulfilled {...props} />}
                />
                <Route
                  path="/Checkout"
                  render={(props) => <Checkout {...props} />}
                />
                <Route
                  path="/editproduct"
                  render={(props) => <EditProduct {...props} />}
                />
                <Route
                  path="/sales-over-time"
                  render={(props) => <SalesOverTime {...props} />}
                />
                <Route
                  path="/sales-by-product-over-time"
                  render={(props) => <SalesByProductOverTime {...props} />}
                />
                <Route
                  path="/orders-over-time"
                  render={(props) => <OrdersOverTime {...props} />}
                />
                <Route
                  path="/fulfilled-orders-over-time"
                  render={(props) => <FulfilledOrdersOverTime {...props} />}
                />
                <Route
                  path="/product-order-return-over-time"
                  render={(props) => (
                    <ProductOrderAndReturnOverTime {...props} />
                  )}
                />
                <Route
                  path="/percent-of-inventory-sold-over-time"
                  render={(props) => <PercentOfInventorySold {...props} />}
                />
                <Route
                  path="/average-inventory-sold-over-time"
                  render={(props) => (
                    <AverageInventorySoldOverTime {...props} />
                  )}
                />
                <Route
                  path="/customers-over-time"
                  render={(props) => <CustomersOverTime {...props} />}
                />
                <Route
                  path="/returning-customers-over-time"
                  render={(props) => <ReturningCustomersOverTime {...props} />}
                />
                <Route
                  path="/onetime-customers-over-time"
                  render={(props) => <OneTimeCustomersOverTime {...props} />}
                />
                <Route
                  path="/text-editor"
                  render={(props) => <TextEditor {...props} />}
                />
                <Route
                  path="/inventryHistory"
                  render={(props) => <InventoryHistory {...props} />}
                />
                <Route
                  path="/variant"
                  render={(props) => <Variant {...props} />}
                />
                <Route
                  path="/editvariant"
                  render={(props) => <EditVariant {...props} />}
                />{" "}
                <Route
                  path="/checkout"
                  render={(props) => <Checkout {...props} />}
                />
                <Route path="*" render={() => <NotFound />} />
              </Switch>
            )}

          {keycloak &&
            keycloak.resourceAccess.instance &&
            isAgent &&
            !isAdmin &&
            !isManager && (
              <Switch>
                <Route
                  path="/POS"
                  exact
                  render={(props) => <PosContainer {...props} />}
                />
                <Route
                  path="/checkout"
                  render={(props) => <Checkout {...props} />}
                />
                <Redirect to="/POS" />
                <Route path="*" render={() => <NotFound />} />
              </Switch>
            )}

          {/* here admin routes start  FOR HPOS*/}
          {keycloak &&
            keycloak?.resourceAccess?.instance &&
            (keycloak?.tokenParsed?.realm_access?.roles[2] == "Admin" ||
              isAdmin) &&
            appType === "HPOS-" && (
              <Switch>
                <Route
                  path="/"
                  exact
                  render={(props) => <ProductList {...props} />}
                />
                <Route
                  path="/change-company"
                  exact
                  render={(props) => (
                    <KeycloackForm {...props} keycloak={keycloak} />
                  )}
                />
                <Route
                  path="/Vendors"
                  render={(props) => <Vendors />}
                  // render={(props) => <ViewOrders {...props} />}
                />
                <Route
                  path="/addVendor"
                  render={(props) => <AddVendor {...props} />}
                  // render={(props) => <ViewOrders {...props} />}
                />
                <Route
                  path="/Purchase Orders"
                  render={(props) => <ViewPurchaseOrder {...props} />}
                  // render={(props) => <ViewOrders {...props} />}
                />
                <Route
                  path="/ViewPurchaseOrders"
                  render={(props) => <PurchaseOrders {...props} />}
                  // render={(props) => <ViewOrders {...props} />}
                />
                <Route
                  path="/CreateOrders"
                  render={(props) => <Orders {...props} />}
                  // render={(props) => <ViewOrders {...props} />}
                />
                <Route
                  path="/Sales Order"
                  // render={(props) => <Orders {...props} />}
                  render={(props) => <ViewOrders {...props} />}
                />
                <Route
                  path="/order/status/:id"
                  render={(props) => <OrderStatus {...props} />}
                />
                <Route
                  path="/ViewOrders"
                  render={(props) => <ViewOrders {...props} />}
                />
                <Route
                  path="/order/cancel/:id"
                  render={(props) => <CancelOrder {...props} />}
                />
                <Route
                  path="/Discount"
                  render={(props) => <Discounts {...props} />}
                />
                <Route
                  path="/AutomatedDiscount"
                  render={(props) => <AutomatedDiscount {...props} />}
                />
                <Route
                  path="/editdiscount"
                  render={(props) => <EditDiscount {...props} />}
                />
                <Route
                  path="/ViewOrder"
                  render={(props) => <ViewOrder {...props} />}
                />
                <Route
                  path="/Customers"
                  render={(props) => <Customers {...props} />}
                />
                <Route
                  path="/Pos"
                  render={(props) => <PosContainer {...props} />}
                />
                <Route
                  path="/Products"
                  render={(props) => <ProductList {...props} />}
                />
                <Route
                  path="/inventory"
                  render={(props) => <Inventory {...props} />}
                />
                <Route
                  path="/Analytics"
                  render={(props) => <AnalyticsDashboard {...props} />}
                />
                <Route
                  path="/ProductForm"
                  render={(props) => <AddProductForm {...props} />}
                />
                <Route
                  path="/editcustomer"
                  render={(props) => <EditCustomer {...props} />}
                />
                <Route
                  path="/customer"
                  render={(props) => <AddCustomerForm {...props} />}
                />
                <Route
                  path="/Pos"
                  render={(props) => <PosContainer {...props} />}
                />
                <Route
                  path="/discountCode"
                  render={(props) => <DiscountCodesForm {...props} />}
                />
                <Route
                  exact
                  path="/DiscountautomatedForm"
                  render={(props) => <AutomatedDiscountForm {...props} />}
                />
                <Route
                  path="/edit"
                  render={(props) => <EditOrder {...props} />}
                />
                <Route
                  path="/editAutomatedDiscount"
                  render={(props) => <EditAutomdatedDiscount {...props} />}
                />
                <Route
                  path="/refund"
                  render={(props) => <Refund {...props} />}
                />
                <Route
                  path="/fulfill"
                  render={(props) => <MarkAsFulfilled {...props} />}
                />
                <Route
                  path="/Checkout"
                  render={(props) => <Checkout {...props} />}
                />
                <Route
                  path="/editproduct"
                  render={(props) => <EditProduct {...props} />}
                />
                <Route
                  path="/sales-over-time"
                  render={(props) => <SalesOverTime {...props} />}
                />
                <Route
                  path="/sales-by-product-over-time"
                  render={(props) => <SalesByProductOverTime {...props} />}
                />
                <Route
                  path="/orders-over-time"
                  render={(props) => <OrdersOverTime {...props} />}
                />
                <Route
                  path="/fulfilled-orders-over-time"
                  render={(props) => <FulfilledOrdersOverTime {...props} />}
                />
                <Route
                  path="/profit-by-product-over-time"
                  render={(props) => <ProfitByProductOverTime {...props} />}
                />
                <Route
                  path="/product-order-return-over-time"
                  render={(props) => (
                    <ProductOrderAndReturnOverTime {...props} />
                  )}
                />
                <Route
                  path="/percent-of-inventory-sold-over-time"
                  render={(props) => <PercentOfInventorySold {...props} />}
                />
                <Route
                  path="/average-inventory-sold-over-time"
                  render={(props) => (
                    <AverageInventorySoldOverTime {...props} />
                  )}
                />
                <Route
                  path="/customers-over-time"
                  render={(props) => <CustomersOverTime {...props} />}
                />
                <Route
                  path="/returning-customers-over-time"
                  render={(props) => <ReturningCustomersOverTime {...props} />}
                />
                <Route
                  path="/onetime-customers-over-time"
                  render={(props) => <OneTimeCustomersOverTime {...props} />}
                />
                <Route
                  path="/customerAllOrders"
                  render={(props) => <CustomersAllOrders {...props} />}
                />
                <Route
                  path="/Details"
                  render={(props) => <ProductDetails {...props} />}
                />
                <Route
                  path="/VariantDetails"
                  render={(props) => <VariantDetails {...props} />}
                />
                <Route
                  path="/text-editor"
                  render={(props) => <TextEditor {...props} />}
                />
                <Route
                  path="/inventryHistory"
                  render={(props) => <InventoryHistory {...props} />}
                />
                <Route
                  path="/variant"
                  render={(props) => <Variant {...props} />}
                />
                <Route
                  path="/editvariant"
                  render={(props) => <EditVariant {...props} />}
                />{" "}
                <Route
                  path="/checkout"
                  render={(props) => <Checkout {...props} />}
                />
                <Route
                  path="/Settings"
                  render={(props) => <Settings {...props} />}
                />
                <Route
                  path="/Profile"
                  render={(props) => (
                    <Profile
                      keycloak={keycloak}
                      {...props}
                      userInfo={userInfo}
                    />
                  )}
                />
                <Route
                  path="/users"
                  render={(props) => (
                    <UserManagement
                      {...props}
                      keycloak={keycloak}
                      token={token}
                    />
                  )}
                />
                <Route path="*" render={() => <NotFound />} />
              </Switch>
            )}

          {/* HERE IS ADMIN routes for HPRMS */}
          {keycloak &&
            keycloak?.resourceAccess?.instance &&
            (keycloak?.tokenParsed?.realm_access?.roles[2] == "Admin" ||
              isAdmin) &&
            appType === "HPRMS-" && (
              <Switch>
                <Route
                  path="/"
                  exact
                  render={(props) => <ViewPurchaseOrder {...props} />}
                />
                <Route
                  path="/change-company"
                  exact
                  render={(props) => (
                    <KeycloackForm {...props} keycloak={keycloak} />
                  )}
                />
                <Route
                  path="/Products"
                  render={(props) => <ProductList {...props} />}
                />
                <Route
                  path="/ProductForm"
                  render={(props) => <AddProductForm {...props} />}
                />
                <Route
                  path="/inventory"
                  render={(props) => <Inventory {...props} />}
                />
                <Route
                  path="/Vendors"
                  render={(props) => <Vendors />}
                  // render={(props) => <ViewOrders {...props} />}
                />
                <Route
                  path="/addVendor"
                  render={(props) => <AddVendor {...props} />}
                  // render={(props) => <ViewOrders {...props} />}
                />
                <Route
                  path="/Purchase Orders"
                  render={(props) => <ViewPurchaseOrder {...props} />}
                  // render={(props) => <ViewOrders {...props} />}
                />
                <Route
                  path="/ViewPurchaseOrders"
                  render={(props) => <PurchaseOrders {...props} />}
                  // render={(props) => <ViewOrders {...props} />}
                />

                <Route path="*" render={() => <NotFound />} />
              </Switch>
            )}

          {/* here is admin routes for HCRM */}
          {keycloak &&
            keycloak?.resourceAccess?.instance &&
            (keycloak?.tokenParsed?.realm_access?.roles[2] == "Admin" ||
              isAdmin) &&
            appType === "HCRM-" && (
              <Switch>
                <Route
                  path="/"
                  exact
                  render={(props) => <Customers {...props} />}
                />

                <Route
                  path="/Customers"
                  render={(props) => <Customers {...props} />}
                />
                <Route
                  path="/customer"
                  render={(props) => <AddCustomerForm {...props} />}
                />

                <Route
                  path="/editcustomer"
                  render={(props) => <EditCustomer {...props} />}
                />

                <Route path="*" render={() => <NotFound />} />
              </Switch>
            )}

          {/*Here is all routes for HPiMS  */}

          {keycloak &&
            keycloak?.resourceAccess?.instance &&
            (keycloak?.tokenParsed?.realm_access?.roles[2] == "Admin" ||
              isAdmin) &&
            appType === "HPiMS-" && (
              <Switch>
                <Route
                  path="/"
                  exact
                  render={(props) => <ProductList {...props} />}
                />
                <Route
                  path="/inventory"
                  render={(props) => <Inventory {...props} />}
                />
                <Route
                  path="/Products"
                  render={(props) => <ProductList {...props} />}
                />
                <Route
                  path="/ProductForm"
                  render={(props) => <AddProductForm {...props} />}
                />

                <Route
                  path="/Customers"
                  render={(props) => <Customers {...props} />}
                />
                <Route
                  path="/customer"
                  render={(props) => <AddCustomerForm {...props} />}
                />

                <Route
                  path="/editcustomer"
                  render={(props) => <EditCustomer {...props} />}
                />

                <Route path="*" render={() => <NotFound />} />
              </Switch>
            )}

          {/* Here is all rooutes for HOMS */}
          {keycloak &&
            keycloak?.resourceAccess?.instance &&
            (keycloak?.tokenParsed?.realm_access?.roles[2] == "Admin" ||
              isAdmin) &&
            appType === "HOMS-" && (
              <Switch>
                <Route
                  path="/"
                  exact
                  render={(props) => <ViewOrders {...props} />}
                />

                <Route
                  path="/CreateOrders"
                  render={(props) => <Orders {...props} />}
                  // render={(props) => <ViewOrders {...props} />}
                />
                <Route
                  path="/Sales Order"
                  // render={(props) => <Orders {...props} />}
                  render={(props) => <ViewOrders {...props} />}
                />
                <Route
                  path="/order/status/:id"
                  render={(props) => <OrderStatus {...props} />}
                />
                <Route
                  path="/fulfill"
                  render={(props) => <MarkAsFulfilled {...props} />}
                />
                <Route
                  path="/ViewOrders"
                  render={(props) => <ViewOrders {...props} />}
                />
                <Route
                  path="/order/cancel/:id"
                  render={(props) => <CancelOrder {...props} />}
                />

                <Route
                  path="/inventory"
                  render={(props) => <Inventory {...props} />}
                />
                <Route
                  path="/Products"
                  render={(props) => <ProductList {...props} />}
                />
                <Route
                  path="/ProductForm"
                  render={(props) => <AddProductForm {...props} />}
                />

                <Route
                  path="/Customers"
                  render={(props) => <Customers {...props} />}
                />
                <Route
                  path="/customer"
                  render={(props) => <AddCustomerForm {...props} />}
                />

                <Route
                  path="/editcustomer"
                  render={(props) => <EditCustomer {...props} />}
                />
                <Route
                  path="/ViewOrder"
                  render={(props) => <ViewOrder {...props} />}
                />
                <Route path="*" render={() => <NotFound />} />
              </Switch>
            )}

          {/* Here is all routes for HRMS */}
          {keycloak &&
            keycloak?.resourceAccess?.instance &&
            (keycloak?.tokenParsed?.realm_access?.roles[2] == "Admin" ||
              isAdmin) &&
            appType === "HRMS-" && (
              <Switch>
                <Route
                  path="/"
                  exact
                  render={(props) => <AnalyticsDashboard {...props} />}
                />
                <Route
                  path="/change-company"
                  exact
                  render={(props) => (
                    <KeycloackForm {...props} keycloak={keycloak} />
                  )}
                />
                <Route
                  path="/Vendors"
                  render={(props) => <Vendors />}
                  // render={(props) => <ViewOrders {...props} />}
                />
                <Route
                  path="/addVendor"
                  render={(props) => <AddVendor {...props} />}
                  // render={(props) => <ViewOrders {...props} />}
                />
                <Route
                  path="/Purchase Orders"
                  render={(props) => <ViewPurchaseOrder {...props} />}
                  // render={(props) => <ViewOrders {...props} />}
                />
                <Route
                  path="/ViewPurchaseOrders"
                  render={(props) => <PurchaseOrders {...props} />}
                  // render={(props) => <ViewOrders {...props} />}
                />
                <Route
                  path="/CreateOrders"
                  render={(props) => <Orders {...props} />}
                  // render={(props) => <ViewOrders {...props} />}
                />
                <Route
                  path="/Sales Order"
                  // render={(props) => <Orders {...props} />}
                  render={(props) => <ViewOrders {...props} />}
                />
                <Route
                  path="/order/status/:id"
                  render={(props) => <OrderStatus {...props} />}
                />
                <Route
                  path="/ViewOrders"
                  render={(props) => <ViewOrders {...props} />}
                />
                <Route
                  path="/order/cancel/:id"
                  render={(props) => <CancelOrder {...props} />}
                />
                <Route
                  path="/Discount"
                  render={(props) => <Discounts {...props} />}
                />
                <Route
                  path="/AutomatedDiscount"
                  render={(props) => <AutomatedDiscount {...props} />}
                />
                <Route
                  path="/editdiscount"
                  render={(props) => <EditDiscount {...props} />}
                />
                <Route
                  path="/ViewOrder"
                  render={(props) => <ViewOrder {...props} />}
                />
                <Route
                  path="/Customers"
                  render={(props) => <Customers {...props} />}
                />
                <Route
                  path="/Pos"
                  render={(props) => <PosContainer {...props} />}
                />
                <Route
                  path="/Products"
                  render={(props) => <ProductList {...props} />}
                />
                <Route
                  path="/inventory"
                  render={(props) => <Inventory {...props} />}
                />
                <Route
                  path="/Analytics"
                  render={(props) => <AnalyticsDashboard {...props} />}
                />
                <Route
                  path="/ProductForm"
                  render={(props) => <AddProductForm {...props} />}
                />
                <Route
                  path="/editcustomer"
                  render={(props) => <EditCustomer {...props} />}
                />
                <Route
                  path="/customer"
                  render={(props) => <AddCustomerForm {...props} />}
                />
                <Route
                  path="/Pos"
                  render={(props) => <PosContainer {...props} />}
                />
                <Route
                  path="/discountCode"
                  render={(props) => <DiscountCodesForm {...props} />}
                />
                <Route
                  exact
                  path="/DiscountautomatedForm"
                  render={(props) => <AutomatedDiscountForm {...props} />}
                />
                <Route
                  path="/edit"
                  render={(props) => <EditOrder {...props} />}
                />
                <Route
                  path="/editAutomatedDiscount"
                  render={(props) => <EditAutomdatedDiscount {...props} />}
                />
                <Route
                  path="/refund"
                  render={(props) => <Refund {...props} />}
                />
                <Route
                  path="/fulfill"
                  render={(props) => <MarkAsFulfilled {...props} />}
                />
                <Route
                  path="/Checkout"
                  render={(props) => <Checkout {...props} />}
                />
                <Route
                  path="/editproduct"
                  render={(props) => <EditProduct {...props} />}
                />
                <Route
                  path="/sales-over-time"
                  render={(props) => <SalesOverTime {...props} />}
                />
                <Route
                  path="/sales-by-product-over-time"
                  render={(props) => <SalesByProductOverTime {...props} />}
                />
                <Route
                  path="/orders-over-time"
                  render={(props) => <OrdersOverTime {...props} />}
                />
                <Route
                  path="/fulfilled-orders-over-time"
                  render={(props) => <FulfilledOrdersOverTime {...props} />}
                />
                <Route
                  path="/profit-by-product-over-time"
                  render={(props) => <ProfitByProductOverTime {...props} />}
                />
                <Route
                  path="/product-order-return-over-time"
                  render={(props) => (
                    <ProductOrderAndReturnOverTime {...props} />
                  )}
                />
                <Route
                  path="/percent-of-inventory-sold-over-time"
                  render={(props) => <PercentOfInventorySold {...props} />}
                />
                <Route
                  path="/average-inventory-sold-over-time"
                  render={(props) => (
                    <AverageInventorySoldOverTime {...props} />
                  )}
                />
                <Route
                  path="/customers-over-time"
                  render={(props) => <CustomersOverTime {...props} />}
                />
                <Route
                  path="/returning-customers-over-time"
                  render={(props) => <ReturningCustomersOverTime {...props} />}
                />
                <Route
                  path="/onetime-customers-over-time"
                  render={(props) => <OneTimeCustomersOverTime {...props} />}
                />
                <Route
                  path="/customerAllOrders"
                  render={(props) => <CustomersAllOrders {...props} />}
                />
                <Route
                  path="/Details"
                  render={(props) => <ProductDetails {...props} />}
                />
                <Route
                  path="/VariantDetails"
                  render={(props) => <VariantDetails {...props} />}
                />
                <Route
                  path="/text-editor"
                  render={(props) => <TextEditor {...props} />}
                />
                <Route
                  path="/inventryHistory"
                  render={(props) => <InventoryHistory {...props} />}
                />
                <Route
                  path="/variant"
                  render={(props) => <Variant {...props} />}
                />
                <Route
                  path="/editvariant"
                  render={(props) => <EditVariant {...props} />}
                />{" "}
                <Route
                  path="/checkout"
                  render={(props) => <Checkout {...props} />}
                />
                <Route
                  path="/Settings"
                  render={(props) => <Settings {...props} />}
                />
                <Route
                  path="/Profile"
                  render={(props) => (
                    <Profile
                      keycloak={keycloak}
                      {...props}
                      userInfo={userInfo}
                    />
                  )}
                />
                <Route
                  path="/users"
                  render={(props) => (
                    <UserManagement
                      {...props}
                      keycloak={keycloak}
                      token={token}
                    />
                  )}
                />
                <Route path="*" render={() => <NotFound />} />
              </Switch>
            )}

          {/* here is all routes for HVMS */}
          {keycloak &&
            keycloak?.resourceAccess?.instance &&
            (keycloak?.tokenParsed?.realm_access?.roles[2] == "Admin" ||
              isAdmin) &&
            appType === "HVMS-" && (
              <Switch>
                <Route path="/" exact render={(props) => <Vendors />} />
                <Route
                  path="/change-company"
                  exact
                  render={(props) => (
                    <KeycloackForm {...props} keycloak={keycloak} />
                  )}
                />
                <Route
                  path="/Vendors"
                  render={(props) => <Vendors />}
                  // render={(props) => <ViewOrders {...props} />}
                />
                <Route
                  path="/addVendor"
                  render={(props) => <AddVendor {...props} />}
                  // render={(props) => <ViewOrders {...props} />}
                />
                <Route
                  path="/Purchase Orders"
                  render={(props) => <ViewPurchaseOrder {...props} />}
                  // render={(props) => <ViewOrders {...props} />}
                />
                <Route
                  path="/ViewPurchaseOrders"
                  render={(props) => <PurchaseOrders {...props} />}
                  // render={(props) => <ViewOrders {...props} />}
                />
                <Route
                  path="/CreateOrders"
                  render={(props) => <Orders {...props} />}
                  // render={(props) => <ViewOrders {...props} />}
                />
                <Route
                  path="/Sales Order"
                  // render={(props) => <Orders {...props} />}
                  render={(props) => <ViewOrders {...props} />}
                />
                <Route
                  path="/order/status/:id"
                  render={(props) => <OrderStatus {...props} />}
                />
                <Route
                  path="/ViewOrders"
                  render={(props) => <ViewOrders {...props} />}
                />
                <Route
                  path="/order/cancel/:id"
                  render={(props) => <CancelOrder {...props} />}
                />
                <Route
                  path="/Discount"
                  render={(props) => <Discounts {...props} />}
                />
                <Route
                  path="/AutomatedDiscount"
                  render={(props) => <AutomatedDiscount {...props} />}
                />
                <Route
                  path="/editdiscount"
                  render={(props) => <EditDiscount {...props} />}
                />
                <Route
                  path="/ViewOrder"
                  render={(props) => <ViewOrder {...props} />}
                />
                <Route
                  path="/Customers"
                  render={(props) => <Customers {...props} />}
                />
                <Route
                  path="/Pos"
                  render={(props) => <PosContainer {...props} />}
                />
                <Route
                  path="/Products"
                  render={(props) => <ProductList {...props} />}
                />
                <Route
                  path="/inventory"
                  render={(props) => <Inventory {...props} />}
                />
                <Route
                  path="/Analytics"
                  render={(props) => <AnalyticsDashboard {...props} />}
                />
                <Route
                  path="/ProductForm"
                  render={(props) => <AddProductForm {...props} />}
                />
                <Route
                  path="/editcustomer"
                  render={(props) => <EditCustomer {...props} />}
                />
                <Route
                  path="/customer"
                  render={(props) => <AddCustomerForm {...props} />}
                />
                <Route
                  path="/Pos"
                  render={(props) => <PosContainer {...props} />}
                />
                <Route
                  path="/discountCode"
                  render={(props) => <DiscountCodesForm {...props} />}
                />
                <Route
                  exact
                  path="/DiscountautomatedForm"
                  render={(props) => <AutomatedDiscountForm {...props} />}
                />
                <Route
                  path="/edit"
                  render={(props) => <EditOrder {...props} />}
                />
                <Route
                  path="/editAutomatedDiscount"
                  render={(props) => <EditAutomdatedDiscount {...props} />}
                />
                <Route
                  path="/refund"
                  render={(props) => <Refund {...props} />}
                />
                <Route
                  path="/fulfill"
                  render={(props) => <MarkAsFulfilled {...props} />}
                />
                <Route
                  path="/Checkout"
                  render={(props) => <Checkout {...props} />}
                />
                <Route
                  path="/editproduct"
                  render={(props) => <EditProduct {...props} />}
                />
                <Route
                  path="/sales-over-time"
                  render={(props) => <SalesOverTime {...props} />}
                />
                <Route
                  path="/sales-by-product-over-time"
                  render={(props) => <SalesByProductOverTime {...props} />}
                />
                <Route
                  path="/orders-over-time"
                  render={(props) => <OrdersOverTime {...props} />}
                />
                <Route
                  path="/fulfilled-orders-over-time"
                  render={(props) => <FulfilledOrdersOverTime {...props} />}
                />
                <Route
                  path="/profit-by-product-over-time"
                  render={(props) => <ProfitByProductOverTime {...props} />}
                />
                <Route
                  path="/product-order-return-over-time"
                  render={(props) => (
                    <ProductOrderAndReturnOverTime {...props} />
                  )}
                />
                <Route
                  path="/percent-of-inventory-sold-over-time"
                  render={(props) => <PercentOfInventorySold {...props} />}
                />
                <Route
                  path="/average-inventory-sold-over-time"
                  render={(props) => (
                    <AverageInventorySoldOverTime {...props} />
                  )}
                />
                <Route
                  path="/customers-over-time"
                  render={(props) => <CustomersOverTime {...props} />}
                />
                <Route
                  path="/returning-customers-over-time"
                  render={(props) => <ReturningCustomersOverTime {...props} />}
                />
                <Route
                  path="/onetime-customers-over-time"
                  render={(props) => <OneTimeCustomersOverTime {...props} />}
                />
                <Route
                  path="/customerAllOrders"
                  render={(props) => <CustomersAllOrders {...props} />}
                />
                <Route
                  path="/Details"
                  render={(props) => <ProductDetails {...props} />}
                />
                <Route
                  path="/VariantDetails"
                  render={(props) => <VariantDetails {...props} />}
                />
                <Route
                  path="/text-editor"
                  render={(props) => <TextEditor {...props} />}
                />
                <Route
                  path="/inventryHistory"
                  render={(props) => <InventoryHistory {...props} />}
                />
                <Route
                  path="/variant"
                  render={(props) => <Variant {...props} />}
                />
                <Route
                  path="/editvariant"
                  render={(props) => <EditVariant {...props} />}
                />{" "}
                <Route
                  path="/checkout"
                  render={(props) => <Checkout {...props} />}
                />
                <Route
                  path="/Settings"
                  render={(props) => <Settings {...props} />}
                />
                <Route
                  path="/Profile"
                  render={(props) => (
                    <Profile
                      keycloak={keycloak}
                      {...props}
                      userInfo={userInfo}
                    />
                  )}
                />
                <Route
                  path="/users"
                  render={(props) => (
                    <UserManagement
                      {...props}
                      keycloak={keycloak}
                      token={token}
                    />
                  )}
                />
                <Route path="*" render={() => <NotFound />} />
              </Switch>
            )}
        </main>
      </div>
      {/* )} */}
    </>
  );
}

export default ResponsiveDrawer;
