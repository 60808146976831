const initial_state = {
  inventoryItems: [],
};

const inventoryReducer = (state = initial_state, action) => {
  switch (action.type) {
    case "GET_INVENTORY_ITEMS":
      console.log(action);
      return Object.assign({}, state, {
        inventoryItems: action.inventoryItems,
      });
    case "EDIT_INVENTORY_ITEM_QUANTITY":
      const updated_inventoryItem = action.inventoryItems;
      console.log("Update Item", updated_inventoryItem);

      return {
        ...state,
        inventoryItems: state.inventoryItems.map((item) => {
          if (item.POID === updated_inventoryItem.POID) {
            return { ...item, quantity: updated_inventoryItem.quantity };
          }
          return item;
        }),
      };

    case "EDIT_INVENTORY_ITEM_QUANTITY_ON_ORDER": {
      for (let i = 0; i < action.id.length; i++) {
        let inventoryItem = state.inventoryItems.filter(
          (inventoryItem) => inventoryItem.POID == action.id[i]
        );
        inventoryItem[0].quantity =
          inventoryItem[0]?.quantity - action?.quantity[i];
      }
      return state;
    }
    case "EDIT_INVENTORY_ITEM": {
      let updated_inventoryItem = action.inventoryItem;
      let inventoryItem = state.inventoryItems.filter(
        (inventoryItem) => inventoryItem.POID === updated_inventoryItem.POID
      );
      inventoryItem = inventoryItem[0];
      if (inventoryItem.quantity !== updated_inventoryItem.quantity) {
        inventoryItem.quantity = updated_inventoryItem.quantity;
      }
      if (
        inventoryItem.unitOfMeasureId !== updated_inventoryItem.unitOfMeasureId
      ) {
        inventoryItem.unitOfMeasureId = updated_inventoryItem.unitOfMeasureId;
      }
      return state;
    }
    case "CLEAR_INVENTORY_DATA":
      return initial_state;
    default:
      return state;
  }
};

export default inventoryReducer;
