import * as React from "react";
import { useHistory } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import ViewCompactIcon from "@mui/icons-material/ViewCompact";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import CardMedia from "@mui/material/CardMedia";
import HULM_LOGO from "../../assets/logo.png";
import Store from "../../index";
import { useSelector, useDispatch } from "react-redux";
import { Badge } from "@mui/material";
import globalReducer from "../../Redux/reducers/global_reducer";
import AppsIcon from "@mui/icons-material/Apps";
import { SKY_BLUE, LIGHT_GREY, WHITE } from "../../colors/colors";
import helpers from "../../UtilitiesFunctions";
import ConstructionIcon from "@mui/icons-material/Construction";
import {
  AirportShuttleSharp,
  GroupAdd,
  GroupAddSharp,
  LocalAtmSharp,
  PostAddSharp,
  RestoreFromTrash,
  ShowChart,
  ShowChartSharp,
  ViewModuleSharp,
} from "@material-ui/icons";
import { Logout } from "@mui/icons-material";

const pages = [];
const settings = [
  "Orders",
  "Products",
  "Inventory",
  "POS",
  "Customers",
  "Discounts",
  "Analytics",
  "Users",
];

const ResponsiveAppBar = (props) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElApp, setAnchorElApp] = React.useState(null);
  const [companyId, setCompanyId] = React.useState("");
  const [userpurchasesapps, setAllallpurchases] = React.useState([]);
  const dispatch = useDispatch();
  const { userInfo } = props;
  const { companyName, instanceId, keyCloakAuth, gatewayUrl, appType } =
    useSelector((state) => state.globalState);
  const { keycloak } = props;
  let rolee = "";
  let clientId = keycloak.clientId;
  if (keycloak.resourceAccess != undefined) {
    Object.entries(keycloak?.resourceAccess).forEach((a) => {
      if (a[0] == clientId) {
        console.log(a[1].roles[0]);
        rolee = a[1].roles;
      }
    });
  }

  var isAdmin =
    keycloak.tokenParsed?.realm_access?.roles.filter(function (val) {
      return val === "Admin";
    }).length > 0 || rolee.includes("Admin");
  var isManager =
    keycloak.tokenParsed?.realm_access?.roles.filter(function (val) {
      return val === "manager";
    }).length > 0 || rolee.includes("manager");
  var isAgent =
    keycloak.tokenParsed?.realm_access?.roles.filter(function (val) {
      return val === "agent" || rolee.includes("agent");
    }).length > 0;
  console.log("Admin From app bar", isAdmin);
  console.log("manager from appbar", isManager);
  console.log("Agent from appbar", isAgent);
  //for route navigation
  let history = useHistory();
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenAppMenu = (event) => {
    setAnchorElApp(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleClick = (index) => {
    console.log("index of user settings is: " + index);
    if (index === 1) logout();
    else if (index === 0) redirectToProfile();
    setAnchorElNav(null);
  };

  function getcompanydetail() {
    let url = gatewayUrl + `/company/getCompanyInfo?userName=${companyName}`;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
    })
      .then((response) => response.json())
      .then(async (resp) => {
        console.log("here is resp from userapp bar ", resp.CID);

        await Store.dispatch({
          type: "BUSSINESS_TYPE",
          payload: {
            bussinessType: resp.CBusinessType,
          },
        });

        setCompanyId(resp.CID);
        // helpers.toastNotify("success", "Vendor Created successfully");
        // this.setState({ backdrop: false });
        // this.redirectToVendors();
      })
      .catch((err) => {
        console.log("here is err of addvendor individual", err);
        // this.setState({ backdrop: false });
      });
  }

  function getcompanypurchases() {
    let url = gatewayUrl + `/company/getAllCompanyProducts?CID=${companyId}`;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
    })
      .then((response) => response.json())
      .then((resp) => {
        console.log("here is all purchases ", resp);
        setAllallpurchases(resp);
        // helpers.toastNotify("success", "Vendor Created successfully");
        // this.setState({ backdrop: false });
        // this.redirectToVendors();
      })
      .catch((err) => {
        console.log("here is err of addvendor individual", err);
        // this.setState({ backdrop: false });
      });
  }

  // function getcompanypurchase() {
  //   if (!companydetaildata || !companydetaildata.CID) {
  //     return;
  //   }

  //   axios
  //     .get(
  //       `${Config.gatewayUrl}/company/getAllCompanyProducts?CID=${companydetaildata?.CID}`
  //     )
  //     .then((response) => {
  //       if (response.status == 200) {
  //         setPurchases(response.data);
  //         console.log("here is all company purchases", response.data);
  //       }
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }

  React.useEffect(() => {
    getcompanydetail();
    getcompanypurchases();
  }, [userInfo, companyId]);

  const logout = async () => {
    helpers.clearData(); // clearing redux state except globalState (need this for initialization of keycloak using companyName and instanceId)
    keycloak.logout(); // keycloak logout
  };

  const redirectToProfile = () => {
    const profileUrl = `${keyCloakAuth}realms/${companyName}/account/`;
    window.open(profileUrl, "_blank");
  };
  const redirectToPOS = () => {
    history.push("/POS");
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseAppMenu = () => {
    setAnchorElApp(null);
  };

  const userIsLoggedIn = () => {
    const { userInfo } = props;
    console.log("here is userinfo from appbar", userInfo);

    return (
      <>
        <Box sx={{ display: { md: "flex" } }}></Box>

        <Box sx={{ flexGrow: 0, display: { md: "flex" }, marginRight: "20px" }}>
          <Tooltip title="Apps">
            <IconButton onClick={handleOpenAppMenu} sx={{ mr: "10%" }}>
              {/* <ViewCompactIcon sx={{ color: "white" }} /> */}
              <AppsIcon sx={{ color: "white" }} />

              <Typography
                // sx={{ display: { xs: "none" } }}
                ml={"4%"}
                mr={"4%"}
                color={WHITE}
                style={{ display: "flex" }}
              >
                Apps
              </Typography>
              {/* <ArrowDropDownCircleOutlinedIcon sx={{ color: "white" }} /> */}
            </IconButton>
          </Tooltip>
        </Box>

        <Box sx={{ flexGrow: 0, display: { md: "flex" } }}>
          <Tooltip title="User settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ mr: "10%" }}>
              <Avatar
                // sx={{ display: { xs: "none" } }}
                alt={userInfo.preferred_username}
                src="/static/images/avatar/2.jpg"
              />
              <Typography
                // sx={{ display: { xs: "none" } }}
                ml={"4%"}
                mr={"2%"}
                color={WHITE}
                style={{ display: "flex" }}
                width={"150px"}
              >
                {userInfo.given_name} -
                {(isAdmin && "Admin") ||
                  (isManager && "Manager") ||
                  (isAgent && "Agent")}
              </Typography>
              <ArrowDropDownCircleOutlinedIcon sx={{ color: "white" }} />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {/* {settings.map((setting, index) => ( */}
            <MenuItem
              onClick={() => {
                history.push("/Orders");
                handleCloseUserMenu();
              }}
              sx={{
                width: "200px",
                display: { lg: "none", md: "none", xl: "none" },
              }}
            >
              <Typography textAlign="center">
                <AirportShuttleSharp /> Orders
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push("/");
                handleCloseUserMenu();
              }}
              sx={{
                width: "200px",
                display: { lg: "none", md: "none", xl: "none" },
              }}
            >
              <Typography textAlign="center">
                {" "}
                <PostAddSharp /> Products
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push("/inventory");
                handleCloseUserMenu();
              }}
              sx={{
                width: "200px",
                display: { lg: "none", md: "none", xl: "none" },
              }}
            >
              <Typography textAlign="center">
                <RestoreFromTrash /> Inventory
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push("/POS");
                handleCloseUserMenu();
              }}
              sx={{
                width: "200px",
                display: { lg: "none", md: "none", xl: "none" },
              }}
            >
              <Typography textAlign="center">
                <LocalAtmSharp /> POS
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push("/Analytics");
                handleCloseUserMenu();
              }}
              sx={{
                width: "200px",
                display: { lg: "none", md: "none", xl: "none" },
              }}
            >
              <Typography textAlign="center">
                <ShowChart /> Analytics
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push("/Customers");
                handleCloseUserMenu();
              }}
              sx={{
                width: "200px",
                display: { lg: "none", md: "none", xl: "none" },
              }}
            >
              <Typography textAlign="center">
                <GroupAddSharp /> Customers
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push("/Discount");
                handleCloseUserMenu();
              }}
              sx={{
                width: "200px",
                display: { lg: "none", md: "none", xl: "none" },
              }}
            >
              <Typography textAlign="center">
                <ViewModuleSharp /> Discount
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push("/Users");
                handleCloseUserMenu();
              }}
              sx={{
                width: "200px",
                display: { lg: "none", md: "none", xl: "none" },
              }}
            >
              <Typography textAlign="center">
                <GroupAdd /> User
              </Typography>
            </MenuItem>
            {/* ))} */}
            <Typography
              style={{
                width: "100%",
                backgroundColor: "#696969",
                marginTop: "10px",
                marginBottom: "10px",
                height: "1px",
              }}
              sx={{ display: { lg: "none", md: "none", xl: "none" } }}
            ></Typography>
            <MenuItem
              onClick={() => redirectToProfile()}
              sx={{ width: "200px" }}
            >
              <Typography textAlign="center">
                <AccountCircle /> Profile
              </Typography>
            </MenuItem>
            <MenuItem onClick={() => logout()} sx={{ width: "200px" }}>
              <Typography textAlign="center">
                <Logout /> Logout
              </Typography>
            </MenuItem>
          </Menu>

          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElApp}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElApp)}
            onClose={handleCloseAppMenu}
          >
            {/* {settings.map((setting, index) => ( */}

            {/* ))} */}
            <Typography
              style={{
                width: "100%",
                backgroundColor: "#696969",
                marginTop: "10px",
                marginBottom: "10px",
                height: "1px",
              }}
              sx={{ display: { lg: "none", md: "none", xl: "none" } }}
            ></Typography>
            <div
              // onClick={() => redirectToProfile()}
              style={{
                width: "auto",
                display: "flex",
                // flexDirection: "row",
                flexWrap: "wrap",
                padding: "10px",
                justifyContent: "space-between",
              }}
            >
              {userpurchasesapps?.length > 0 &&
                userpurchasesapps.map((p) => {
                  console.log("here is active condition", appType);
                  return (
                    <div style={{ marginTop: "10px", marginRight: "5px" }}>
                      <a
                        // href={`${`https://pos.hulmsolutions.com/?companyName=${data?.CName}&instanceId=${data?.PInstanceID}&appType=${data?.PType}`}`}
                        href={`${`http://pos.hulmsolutions.com/?companyName=${p?.CName}&instanceId=${p?.PInstanceID}&appType=${p?.PType}`}`}
                        // href={`${`http://localhost:3000/?companyName=${p?.CName}&instanceId=${p?.PInstanceID}&appType=${p?.PType}`}`}
                        target="_self"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <div
                          style={{
                            width: "70px",
                            border: "1px solid lightgray",
                            borderRadius: "10px",
                            cursor: "pointer",
                            backgroundColor:
                              appType === p.PType + "-" ? "lightgray" : "",
                          }}
                        >
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "5px",
                            }}
                          >
                            {p.PType === "HPOS" && (
                              <img
                                src="./google-analytics.png"
                                alt="chart"
                                height={"20px"}
                                width={"20px"}
                              />
                            )}

                            {p.PType === "HPiMS" && (
                              <img
                                src="./inventory.png"
                                alt="chart"
                                height={"21px"}
                                width={"21px"}
                              />
                            )}

                            {p.PType === "HCRM" && (
                              <img
                                src="./crm.png"
                                alt="chart"
                                height={"21px"}
                                width={"21px"}
                              />
                            )}

                            {p.PType === "HOMS" && (
                              <img
                                src="./checklist.png"
                                alt="chart"
                                height={"21px"}
                                width={"21px"}
                              />
                            )}

                            {p.PType === "HPRMS" && (
                              <img
                                src="./procurement.png"
                                alt="chart"
                                height={"21px"}
                                width={"21px"}
                              />
                            )}

                            {p.PType === "HRMS" && (
                              <img
                                src="./procurement.png"
                                alt="chart"
                                height={"21px"}
                                width={"21px"}
                              />
                            )}

                            {p.PType === "HVMS" && (
                              <img
                                src="./vendor.png"
                                alt="chart"
                                height={"21px"}
                                width={"21px"}
                              />
                            )}

                            {p.PType}
                          </span>
                        </div>
                      </a>
                      {/* <div
                style={{
                  width: "70px",
                  border: "1px solid lightgray",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "5px",
                  }}
                >
                  <Logout /> Logout
                </span>
              </div> */}
                    </div>
                  );
                })}
            </div>
          </Menu>
        </Box>
      </>
    );
  };
  const { authenticated } = props;
  return (
    <AppBar position="fixed" sx={{ backgroundColor: SKY_BLUE }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <CardMedia
            component="img"
            sx={{ width: "3%", mr: 1 }}
            image={HULM_LOGO}
            alt="Hulm Logo"
            onClick={() => history.push("/")}
          />
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
            onClick={() => history.push("/")}
          >
            HULM POS
          </Typography>

          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
          >
            HULM
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {page}
              </Button>
            ))}
          </Box>
          {authenticated ? (
            userIsLoggedIn()
          ) : (
            <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
              <Button
                key={"sign in"}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                SIGN IN
              </Button>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

ResponsiveAppBar.defaultProps = {
  authenticated: false,
  userInfo: {
    preferred_username: "guest ",
  },
};
export default ResponsiveAppBar;
