const initial_state = {
  printer_settings: null,
};
const printerSettingInfoReducer = (state = initial_state, action) => {
  switch (action.type) {
    case "PRINTER_SETTINGS":
      console.log(action);
      return Object.assign({}, state, {
        printer_settings: action.printer_settings,
      });
    case "PUT_PRINTER_SETTINGS":
      return {
        ...state,
        printer_settings: action.printer_settings,
      };
    case "CLEAR_COMPANY_INFO":
      return initial_state;
    default:
      return state;
  }
};

export default printerSettingInfoReducer;
