import React from "react";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { Box, Divider } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import CloseIcon from "@material-ui/icons/Close";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { useState } from "react";

export default function CustomProduct(props) {
  console.log("props.here", props);
  let [qty, setqty] = useState(props.product.quantity);
  let [returnItem, setReturnItem] = useState(0);
  let [price, setPrice] = useState(props.product.price);
  let [stock, setStock] = useState(props.product.stock);
  let [initalStock, setInitialStock] = useState(props.product.stock);
  const priceUpdated = (e) => {
    if (props?.isPriceUpdateable) {
      setPrice(e.target.value);
      props?.updatePrice(parseInt(e.target.value), props?.product?.productName);
    }
  };

  // const handleChange = (e) => {
  //   let inputValue = e.target.value;

  //   // Remove leading zeros if input is not empty
  //   if (inputValue && inputValue.startsWith("0") && inputValue.length > 1) {
  //     inputValue = inputValue.replace(/^0+/, "");
  //   }

  //   // Parse the new quantity, fallback to an empty string if input is empty
  //   const newQuantity = inputValue === "" ? "" : parseInt(inputValue) || 0;
  //   const stockAvailable = parseInt(props.product.stock); // Current stock

  //   // Ensure new quantity does not exceed available stock
  //   if (newQuantity > stockAvailable) {
  //     alert("Quantity cannot exceed available stock!");
  //     return; // Exit the function if the quantity exceeds stock
  //   }

  //   const newStock = stockAvailable - (newQuantity || 0);

  //   if (newQuantity >= 0) {
  //     setStock(newStock);
  //   } else {
  //     setStock(initalStock);
  //   }

  //   if (props.isRefund === false) {
  //     if (props.isEdit) {
  //       setqty(newQuantity); // Update qty only if within limits

  //       const id =
  //         props.product.id == null ? props.product.productId : props.product.id;
  //       props.getQty(
  //         {
  //           id: id,
  //           quantity: newQuantity,
  //           price: props.product.price,
  //           tax: props.product.tax,
  //           // subtotal: (props.product.price * newQuantity) + (props.product.price * newQuantity * props.product.tax) / 100,
  //         },
  //         id
  //       );
  //     } else {
  //       setqty(newQuantity);
  //       props.getQty(newQuantity, props.product.productName);
  //     }
  //   } else if (props.isRefund) {
  //     if (newQuantity > props.product.quantity) {
  //       alert(
  //         "Return quantity must be less than or equal to quantity purchased!"
  //       );
  //     } else {
  //       setReturnItem(newQuantity);
  //       props.getQty(
  //         {
  //           id: props.product.id,
  //           quantity: newQuantity,
  //           returned: true,
  //           price: props.product.price,
  //           tax: props.product.tax,
  //         },
  //         props.product.id
  //       );
  //     }
  //   }
  // };

  const handleChange = (e) => {
    if (props.isRefund === false) {
      if (props.isEdit) {
        setqty(parseInt(e.target.value));

        var id = null;
        if (props.product.id == null) {
          id = props.product.productId;
        } else {
          id = props.product.id;
        }
        props.getQty(
          {
            id: id,
            quantity: parseInt(e.target.value),
            price: props.product.price,
            tax: props.product.tax,
            // subtotal:
            //   props.product.price * parseInt(e.target.value) +
            //   (props.product.price *
            //     parseInt(e.target.value) *
            //     props.product.tax) /
            //     100,
          },
          id
        );
      } else {
        setqty(parseInt(e.target.value));
        props.getQty(parseInt(e.target.value), props.product.productName);
      }
    } else if (props.isRefund) {
      if (e.target.value > props.product.quantity) {
        alert(
          "Return quantity must be less than or equal to qunatity purchased!"
        );
      } else {
        setReturnItem(parseInt(e.target.value));
        props.getQty(
          {
            id: props.product.id,
            quantity: parseInt(e.target.value),
            returned: true,
            price: props.product.price,
            tax: props.product.tax,
          },
          props.product.id
        );
      }
    }
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "10px",
          justifyContent: "space-between",
          flexDirection: "row",
          textAlign: "left",
          backgroundColor: "white",
          margin: "10px",
        }}
      >
        <ShoppingCartIcon style={{ fontSize: "40px", margin: "10px" }} />
        <div
          style={{
            // justifyContent: "space-between",
            flexDirection: "column",

            flex: 1,
          }}
        >
          <div>
            <p style={{}}>
              {props.product.returned ? (
                <>
                  {props.product.name == null
                    ? props.product.productName + " - Returned Item"
                    : props.product.name + " - Returned Item"}
                </>
              ) : (
                <>
                  {props.product.name == null
                    ? props.product.productName
                    : props.product.name}
                </>
              )}
              {/* <br /> Rs {props.product.price} */}
            </p>
          </div>
          <div
            style={{
              justifyContent: "space-between",
              flexDirection: "row",

              display: "flex",
            }}
          >
            <TextField
              size="small"
              type="number"
              value={props.product.price}
              min={0}
              disabled={!props?.isPriceUpdateable}
              id="outlined-basic"
              label="Price Per Unit"
              variant="outlined"
              onChange={priceUpdated}
              inputProps={{
                min: 0,
                classes: {
                  input: {
                    height: 3,
                    marginBottom: "10px",
                  },
                },
              }}
            />

            <TextField
              size="small"
              type="number"
              min={0}
              max={parseInt(props.product.stock, 10)}
              label="Quantity"
              // required
              //value={qty}
              value={props.isRefund ? returnItem : props.product.quantity}
              disabled={props.disableQty}
              variant="outlined"
              onChange={handleChange}
              inputProps={{ min: 0, max: props.product.stock }}
              //InputProps={{ inputProps: { min: 1, max: 200 } }}
              endAdornment={
                props.isRefund ? (
                  <InputAdornment position="end">
                    {"/" + props.product.quantity}
                  </InputAdornment>
                ) : (
                  <></>
                )
              }
            />

            <span>Rs {props.product.price * props.product.quantity}</span>

            <TextField
              size="small"
              style={{ width: 100 }}
              type="number"
              min={0}
              label="Current Stock"
              // required
              //value={qty}
              value={stock}
              disabled={true}
              variant="outlined"
              inputProps={{ min: 0 }}
              //InputProps={{ inputProps: { min: 1, max: 200 } }}
              endAdornment={
                props.isRefund ? (
                  <InputAdornment position="end">
                    {"/" + props.product.quantity}
                  </InputAdornment>
                ) : (
                  <></>
                )
              }
            />

            {props.isDelete ? (
              <>
                <CloseIcon
                  style={{ color: "red", cursor: "pointer" }}
                  onClick={() => props.onDelete(props.product)}
                />
              </>
            ) : (
              <>
                <CloseIcon color="disabled" />
              </>
            )}
          </div>
        </div>
      </div>
      <Divider style={{ background: "gray" }} />
    </>
  );
}
